import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { selectCurrentSupplier, selectPickup, selectWaybillDetails } from '../../selectors/return';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Supplier, WaybillDetails } from '../../models/suppliers';
import { Country } from '../../models/country';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { setWaybillDetails, setPickup, resetDropoffPoint } from '../../actions/return';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss'],
})
export class AddressInfoComponent {
  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier);

  readonly selectedPickup$ = this.store.select(selectPickup);

  readonly waybillDetails$ = this.store.select(selectWaybillDetails);

  readonly config$: Maybe<
    Observable<{
      supplier: Maybe<Supplier>;
    }>
  > = null;

  readonly countries: Country[] = [
    { name: 'South Africa', code: 'ZA' },
    { name: 'Egypt', code: 'EG' },
  ];

  pickupDetailsForm = new UntypedFormGroup({
    address1: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d).+$/),
    ]),
    address2: new UntypedFormControl(''),
    suburb: new UntypedFormControl('', [Validators.required, Validators.pattern(/\w/)]),
    city: new UntypedFormControl('', [Validators.required, Validators.pattern(/\w/)]),
    province: new UntypedFormControl('', [Validators.required, Validators.pattern(/\w/)]),
    postalcode: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\S*$/)]), // Check that postal code includes no spaces
    country: new UntypedFormControl('', [Validators.required]),
  });

  additionalDetailsForm = new UntypedFormGroup({
    additionalDetails: new UntypedFormControl('', [Validators.maxLength(250)]),
  });

  getCountryCode(country: string): string {
    const co = this.countries.find((countryObj: Country) => countryObj.name === country);
    return co?.code ? co.code : country;
  }

  get pickupDetailsValidation(): Record<string, boolean | undefined> {
    const address1 = this.pickupDetailsForm.get('address1');
    const address2 = this.pickupDetailsForm.get('address2');
    const suburb = this.pickupDetailsForm.get('suburb');
    const city = this.pickupDetailsForm.get('city');
    const province = this.pickupDetailsForm.get('province');
    const postalcode = this.pickupDetailsForm.get('postalcode');
    const country = this.pickupDetailsForm.get('country');

    return {
      address1: address1?.touched && address1.invalid,
      address2: address2?.touched && address2.invalid,
      suburb: suburb?.touched && suburb.invalid,
      city: city?.touched && city.invalid,
      province: province?.touched && province.invalid,
      postalcode: postalcode?.touched && postalcode.invalid,
      country: country?.touched && country.invalid,
    };
  }

  get additionalDetailsValidation(): Record<string, boolean | undefined> {
    const additionalDetails = this.additionalDetailsForm.get('additionalDetails');

    return {
      additionalDetails: additionalDetails?.touched && additionalDetails.invalid,
    };
  }

  constructor(private store: Store, public router: Router) {
    this.config$ = combineLatest([
      this.selectedSupplier$,
      this.selectedPickup$,
      this.waybillDetails$,
    ]).pipe(
      take(1),
      map(([supplier, pickup, waybill]) => {
        return { supplier, pickup, waybill };
      }),
    );

    this.config$.subscribe((data: any) => {
      if (data.pickup || data.waybill) {
        this.pickupDetailsForm.patchValue(data.pickup);
        this.additionalDetailsForm.patchValue(data.waybill?.returnDetails);
      }
    });
  }

  getFormData(): WaybillDetails {
    return {
      returnDetails: {
        additionalDetails: this.additionalDetailsForm.get('additionalDetails')?.value,
      },
      pickupDetails: {
        address1: this.pickupDetailsForm.get('address1')?.value,
        address2: this.pickupDetailsForm.get('address2')?.value,
        suburb: this.pickupDetailsForm.get('suburb')?.value,
        city: this.pickupDetailsForm.get('city')?.value,
        province: this.pickupDetailsForm.get('province')?.value,
        postalcode: this.pickupDetailsForm.get('postalcode')?.value,
        country: this.getCountryCode(this.pickupDetailsForm.get('country')?.value),
      },
    };
  }

  selectAddress(address: any): void {
    this.store.dispatch(setPickup({ pickup: address }));
  }

  handleConfirm(): void {
    this.store.dispatch(resetDropoffPoint());
    this.pickupDetailsForm.markAllAsTouched();
    this.additionalDetailsForm.markAllAsTouched();

    if (this.pickupDetailsForm.valid) {
      const waybillDetails = this.getFormData();

      this.selectAddress(waybillDetails.pickupDetails);

      this.store.dispatch(setWaybillDetails({ waybillDetails }));
      this.router.navigate(['/waybill']);
    }
  }

  goBack(): void {
    const waybillDetails = this.getFormData();
    this.store.dispatch(setWaybillDetails({ waybillDetails }));
    this.router.navigate([`/pup-selection`]);
  }
}
