<ng-container *ngIf="selectedSupplier$ | async as supplier">
  <use-media query="(max-width: 1023px)">
    <app-address-info-mobile
      (handleConfirm)="handleConfirm()"
      (goBack)="goBack()"
      [supplier]="supplier"
      [pickupDetailsForm]="pickupDetailsForm"
      [pickupDetailsValidation]="pickupDetailsValidation"
      [additionalDetailsForm]="additionalDetailsForm"
      [additionalDetailsValidation]="additionalDetailsValidation"
    ></app-address-info-mobile>
  </use-media>
  <use-media query="(min-width: 1024px)">
    <app-address-info-desktop
      (handleConfirm)="handleConfirm()"
      (goBack)="goBack()"
      [supplier]="supplier"
      [pickupDetailsForm]="pickupDetailsForm"
      [pickupDetailsValidation]="pickupDetailsValidation"
      [additionalDetailsForm]="additionalDetailsForm"
      [additionalDetailsValidation]="additionalDetailsValidation"
    ></app-address-info-desktop>
  </use-media>
</ng-container>
