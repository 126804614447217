import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-brand-selection-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class BrandSelectionMobileComponent {
  @Output() changeSelectedSupplier = new EventEmitter();

  @Output() bookReturn = new EventEmitter();

  @Output() deselectSupplier = new EventEmitter();

  @Input() searchTerm: any;

  @Input() suppliers$: any;

  @Input() selectedSupplier$: any;

  @Input() searchSuppliers$: any;

  @Input() selectedSupplierId: any;

  @Input() failed: any;
}
