import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReturnsModule } from './modules/returns/returns.module';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

import * as Sentry from '@sentry/angular';

import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { NavigationHeaderComponent } from './components/navigation-header/navigation-header.component';
import { ErrorStateComponent } from './modules/returns/containers/error-state/error-state.component';
import { ReactiveFormsModule } from '@angular/forms';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MediaModule } from 'ng-helpers';

import { VERSION } from './../environments/version';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

/**
 * Namespaces storage keys by app version
 *
 * Using this, we ensure there aren't any state conflicts between application
 * versions. The result is `git-commit-hash` + `key`.
 */
function storageKeySerializer(key: string): string {
  return `${VERSION.suffix}-${key}`;
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  // return localStorageSync({ keys: [{ returns: [{ return: ['currentSupplier', 'landedOnBrand'] }] }], rehydrate: true })(reducer);
  return localStorageSync({
    keys: ['returns'],
    rehydrate: true,
    storageKeySerializer,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent, NavigationHeaderComponent, ErrorStateComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,

    ReturnsModule,

    StoreModule.forRoot({}, { metaReducers }),

    EffectsModule.forRoot([]),

    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),

    NgxHotjarModule.forRoot(environment.hotjarSiteId),
    NgxHotjarRouterModule,
    MediaModule,

    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingCode),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
