import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class WebConfigService {
  public headers() {
    const headers = {
      'Content-Type': 'application/json',
    };

    return {
      headers: new HttpHeaders(headers),
      withCredentials: false,
    };
  }
}
