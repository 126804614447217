<div class="header">
  <h1>
    {{ supplier.name }} Returns
    <img
      [src]="supplier.config?.logo"
      *ngIf="supplier.config?.logo && supplierPupSelectionConfig.displayLogo"
      [alt]="supplier.name"
    />
  </h1>
  <p>{{ supplierPupSelectionConfig.subtitle }}</p>
</div>

<div class="container">
  <div *ngIf="supplierPupSelectionConfig.displayInstructions" class="pup-instructions">
    <div class="instruction">
      <img src="/assets/images/returns/box.svg" alt="" />
      <p>
        Securely pack your item(s) to prevent rejection at the Pargo Point. Remove any old labels or
        stickers.
      </p>
    </div>
    <div class="instruction">
      <img src="/assets/images/returns/print-screen.svg" alt="" />
      <p>
        Not all Pargo Points can accept Amazon Returns. Find your closest eligible drop-off location
        on the map.
      </p>
    </div>
    <div class="instruction">
      <img src="/assets/images/returns/mobile.svg" alt="" />
      <p>
        Show your Amazon Return barcode on your mobile device, or give your Amazon return tracking
        number at the Pargo Point. You will receive a Pargo sticker label to secure to your package.
      </p>
    </div>
    <div class="instruction">
      <img src="/assets/images/returns/drop-it-off.svg" alt="" />
      <p>
        You will receive a confirmation once you have dropped-off your return package. You can get
        help or view your return status, via your Amazon account.
      </p>
    </div>
  </div>

  <div class="pup-container">
    <div *ngIf="supplierPupSelectionConfig.processTypes" class="selection form-group">
      <select
        [formControl]="processTypeControl"
        (change)="changeProcessType.emit(processTypeControl.value)"
      >
        <option
          *ngIf="supplierPupSelectionConfig.processTypes.allPickupPoints"
          value="all"
          [selected]="supplierPupSelectionConfig.defaultProcessType === 'all'"
        >
          All Stores
        </option>
        <option
          *ngIf="supplierPupSelectionConfig.processTypes.paperless"
          value="paperless"
          [selected]="supplierPupSelectionConfig.defaultProcessType === 'paperless'"
        >
          Paperless
        </option>
        <option
          *ngIf="supplierPupSelectionConfig.processTypes.print"
          value="print"
          [selected]="supplierPupSelectionConfig.defaultProcessType === 'print'"
        >
          Print
        </option>
      </select>
    </div>

    <div class="map">
      <div class="map-container">
        <iframe allow="geolocation" [src]="mapSource(supplier, processType)"></iframe>
      </div>
    </div>

    <div class="from-home" *ngIf="supplier?.config?.returnsFromDoorEnabled">
      <p>No Pargo Point nearby?</p>
      <button class="inverted-btn" [routerLink]="['/address']">Return from home</button>
    </div>
  </div>
</div>

<button
  *ngIf="!automaticSelection && supplierPupSelectionConfig.displayBackButton"
  class="back-btn"
  (click)="goBack.emit()"
>
  Back
</button>
