import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable } from 'rxjs';

import { WebConfigService } from './web-config.service';

import { environment } from '../../../../environments/environment';
import { expand, reduce } from 'rxjs/operators';

@Injectable()
export class ReturnsApiService {
  constructor(protected http: HttpClient, private webconfigService: WebConfigService) {}

  static readonly API_URL = environment.returnsApiUrl;

  headers = this.webconfigService.headers();

  getSuppliersPage(page: number): Observable<any> {
    const params = { page, limit: 100 };
    return this.http.get(`${ReturnsApiService.API_URL}/suppliers`, {
      ...this.headers,
      params,
    });
  }

  getSuppliers(): Observable<any> {
    return this.getSuppliersPage(1).pipe(
      expand(({ meta }) =>
        meta.total > meta.page * meta.limit ? this.getSuppliersPage(meta.page + 1) : empty(),
      ),
      reduce((acc, result) => acc.concat(result.data), []),
    );
  }

  getSupplier(id: string): Observable<any> {
    return this.http.get(`${ReturnsApiService.API_URL}/config/${id}`, {
      ...this.headers,
    });
  }

  validateWaybill(waybill: string): Observable<any> {
    return this.http.get(`${ReturnsApiService.API_URL}/existing/${waybill}`, { ...this.headers });
  }

  validateOtp(otp: string): Observable<any> {
    return this.http.post(`${ReturnsApiService.API_URL}/otp`, { otp }, { ...this.headers });
  }

  createReturn(order: any): Observable<any> {
    return this.http.post(`${ReturnsApiService.API_URL}/new`, { ...order }, { ...this.headers });
  }
}
