<ng-container *ngIf="selectedSupplier$ | async as supplier">
  <ng-container *ngIf="selectedPickupPoint$ | async as pickupPoint">
    <use-media query="(max-width: 1023px)">
      <app-waybill-info-mobile
        (handleConfirm)="handleConfirm()"
        (goBack)="goBack($event)"
        [supplier]="supplier"
        [pickupPoint]="pickupPoint"
        [returnDetailsForm]="returnDetailsForm"
        [returnDetailsValidation]="returnDetailsValidation"
        [yourDetailsForm]="yourDetailsForm"
        [yourDetailsValidation]="yourDetailsValidation"
      ></app-waybill-info-mobile>
    </use-media>
    <use-media query="(min-width: 1024px)">
      <app-waybill-info-desktop
        (handleConfirm)="handleConfirm()"
        (goBack)="goBack($event)"
        [supplier]="supplier"
        [pickupPoint]="pickupPoint"
        [returnDetailsForm]="returnDetailsForm"
        [returnDetailsValidation]="returnDetailsValidation"
        [yourDetailsForm]="yourDetailsForm"
        [yourDetailsValidation]="yourDetailsValidation"
      ></app-waybill-info-desktop>
    </use-media>
  </ng-container>
</ng-container>
