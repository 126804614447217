<ng-container *ngIf="selectedSupplier$ | async as supplier">
  <ng-container *ngIf="selectedPickupPoint$ | async as pickupPoint">
    <ng-container *ngIf="waybillDetails$ | async as waybillDetails">
      <ng-container *ngIf="waybillValidation$ | async as waybillValidation">
        <ng-container *ngIf="dropoff$ | async as dropoff">
          <ng-container *ngIf="selectedPickupAddress$ | async as pickupAddress">
            <ng-container *ngIf="waybillType$ | async as waybillType">
              <use-media query="(max-width: 1023px)">
                <app-confirmation-mobile
                  (download)="download($event)"
                  [supplier]="supplier"
                  [dropoff]="dropoff"
                  [pickupPoint]="pickupPoint"
                  [waybillDetails]="waybillDetails"
                  [waybillValidation]="waybillValidation"
                  [pickupAddress]="pickupAddress"
                  [waybillType]="waybillType"
                ></app-confirmation-mobile>
              </use-media>
              <use-media query="(min-width: 1024px)">
                <app-confirmation-desktop
                  (download)="download($event)"
                  [supplier]="supplier"
                  [dropoff]="dropoff"
                  [pickupPoint]="pickupPoint"
                  [waybillDetails]="waybillDetails"
                  [waybillValidation]="waybillValidation"
                  [pickupAddress]="pickupAddress"
                  [waybillType]="waybillType"
                ></app-confirmation-desktop>
              </use-media>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
