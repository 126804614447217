import { Component } from '@angular/core';
import {
  selectCurrentSupplier,
  selectGetSupplierFailed,
  selectLandedOnBrand,
} from '../../selectors/return';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Observable, of, Subscription } from 'rxjs';
import { debounce, map, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Supplier } from '../../models/suppliers';
import { getSupplier, setLandedOnBrand, setPickup, setSearchAddress } from '../../actions/return';

import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LocationAddress } from '../../reducers/return';

import { environment } from '../../../../../environments/environment';

interface Suggestion extends LocationAddress {
  place_name: string;
}

@Component({
  selector: 'app-brand-landing',
  templateUrl: './brand-landing.component.html',
  styleUrls: ['./brand-landing.component.scss'],
})
export class BrandLandingComponent {
  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier);

  readonly fetchSupplierFailed$ = this.store.select(selectGetSupplierFailed);

  readonly landedOnBrand$ = this.store.select(selectLandedOnBrand);

  readonly addressSearchControl = new FormControl('');

  protected suggestionsCache: Record<string, any>[] = [];

  public autofillSuggestions$: Observable<Suggestion[]> =
    this.addressSearchControl.valueChanges.pipe(
      debounce(() => interval(250)),
      switchMap((value) => {
        if (value.length < 5) {
          return of([]);
        }

        return this.suggest(String(value));
      }),
      map((value) => {
        this.suggestionsCache = value;
        return value;
      }),
    );

  private params$: Subscription;

  private fetchSupplierFailedSub$: Subscription;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    public router: Router,
    private http: HttpClient,
  ) {
    this.params$ = combineLatest(this.route.params, this.selectedSupplier$)
      .pipe(take(1))
      .subscribe((args) => this.handlePreloadBrand(...args));
    this.fetchSupplierFailedSub$ = this.fetchSupplierFailed$.subscribe((failed) => {
      if (failed) {
        this.router.navigate(['/error']);
      }
    });
  }

  handlePreloadBrand(params: Params, supplier?: Supplier): void {
    if (params.segment) {
      this.store.dispatch(setLandedOnBrand({ landed: true }));
      this.store.dispatch(getSupplier({ id: params.segment }));
    }

    if (!params.segment && !supplier?.config) {
      this.router.navigate(['/']);
    }
  }

  navigate(url: any): void {
    this.router.navigate(url);
  }

  handleAutofillQuery(address: string): void {
    this.addressSearchControl.setValue(address);
  }

  selectAddress(address: string): void {
    this.store.dispatch(setSearchAddress({ address }));
    this.store.dispatch(
      setPickup({ pickup: <any>this.suggestionsCache.find((x) => x.place_name === address) }),
    );
  }

  suggest(address: string): Observable<Suggestion[]> {
    return this.http
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json`,
        {
          params: {
            access_token: environment.defaultMapToken,
            limit: 5,
            proximity: 'ip',
            types: 'address',
            autocomplete: true,
            country: 'za,bw,ls,na,sz,eg',
            fuzzyMatch: false,
          },
        },
      )
      .pipe(
        map((response: Record<string, any>) => {
          if (response && 'features' in response) {
            return response.features
              .map((feature: Record<string, any>) => {
                if (!feature.context) {
                  return null; // Skip this feature if context is undefined
                }
                const context = Object.values(feature.context);
                const find = (ctx: any, key: string) =>
                  ctx.find((item: Record<string, any>) => item.id.startsWith(key))?.text;

                const address1 = feature.place_name.split(',', 1)[0];

                return {
                  place_name: feature.place_name,
                  address1: `${address1}`,
                  city: find(context, 'place'),
                  suburb: find(context, 'neighborhood'),
                  province: find(context, 'region'),
                  country: find(context, 'country'),
                  postalcode: find(context, 'postcode'),
                };
              })
              .filter(Boolean); // Filter out any undefined values
          }

          return [];
        }),
      );
  }
}
