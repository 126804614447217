// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  returnsApiUrl: 'https://returns-backend.staging.pargo.co',
  defaultMapToken:
    'pk.eyJ1IjoicGFyZ29wb2ludCIsImEiOiJjbGJlcmo4Z3UwNm5jM25uYXB2NmxrZWFrIn0.M2ZJYUkkB4Mt8wJEvDKfmg',
  defaultMapApi: 'https://map.staging.pargo.co.za',
  assetUrl: '',
  sentryDsn: 'https://c17af289faf548b29b2cf1e8c6fb677c@o640290.ingest.sentry.io/6193402',
  sentryTracesSampleRate: 1.0,
  sentryTracingOrigins: ['returns-backend.staging.pargo.co'],
  hotjarSiteId: '2603624',
  googleAnalyticsTrackingCode: 'G-8NCLS9KHS8',
  amazonSupId: 'a486f61e-2a78-4854-a7d7-4c6a7529f737',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
