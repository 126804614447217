import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { NAVIGATE } from '../actions/navigation';

@Injectable()
export class NavigationEffects {
  constructor(private actions: Actions<any>, private router: Router) {}

  public navigate$: any = createEffect(
    () =>
      this.actions.pipe(
        ofType<any>(NAVIGATE),
        tap((action: { url: string[] }) => this.router.navigate(action.url)),
      ),
    { dispatch: false },
  );
}
