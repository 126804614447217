import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { EffectsModule } from '@ngrx/effects';

import { Store } from './store';
import { ReturnEffects } from './effects/return.effects';

import { WebConfigService } from './services/web-config.service';
import { ReturnsApiService } from './services/returns-api.service';

import { ReturnsRoutingModule } from './returns.routing.module';
import { BrandSelectionComponent } from './containers/brand-selection/brand-selection.component';
import { PupSelectionComponent } from './containers/pup-selection/pup-selection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WaybillInfoComponent } from './containers/waybill-info/waybill-info.component';
import { BrandLandingComponent } from './containers/brand-landing/brand-landing.component';
import { WaybillConfirmationComponent } from './containers/waybill-confirmation/waybill-confirmation.component';
import { ConfirmationComponent } from './containers/confirmation/confirmation.component';
import { MediaModule } from 'ng-helpers';
import { BrandLandingDesktopComponent } from './containers/brand-landing/components/desktop/desktop.component';
import { BrandLandingMobileComponent } from './containers/brand-landing/components/mobile/mobile.component';
import { BrandSelectionDesktopComponent } from './containers/brand-selection/components/desktop/desktop.component';
import { BrandSelectionMobileComponent } from './containers/brand-selection/components/mobile/mobile.component';
import { PupSelectionDesktopComponent } from './containers/pup-selection/components/desktop/desktop.component';
import { PupSelectionMobileComponent } from './containers/pup-selection/components/mobile/mobile.component';
import { WaybillInfoMobileComponent } from './containers/waybill-info/components/mobile/mobile.component';
import { WaybillInfoDesktopComponent } from './containers/waybill-info/components/desktop/desktop.component';
import { WaybillConfirmationDesktopComponent } from './containers/waybill-confirmation/desktop/desktop.component';
import { WaybillConfirmationMobileComponent } from './containers/waybill-confirmation/mobile/mobile.component';
import { ConfirmationDesktopComponent } from './containers/confirmation/components/desktop/desktop.component';
import { ConfirmationMobileComponent } from './containers/confirmation/components/mobile/mobile.component';
import { NavigationEffects } from './effects/navigation.effects';
import { AddressInfoComponent } from './containers/address-info/address-info.component';
import { AddressInfoDesktopComponent } from './containers/address-info/components/desktop/desktop.component';
import { AddressInfoMobileComponent } from './containers/address-info/components/mobile/mobile.component';
import { AmazonComponent } from './containers/brand-specific-journeys/amazon/amazon.component';

@NgModule({
  declarations: [
    // Containers
    BrandSelectionComponent,
    BrandLandingComponent,
    PupSelectionComponent,
    WaybillInfoComponent,
    WaybillConfirmationComponent,
    ConfirmationComponent,
    AddressInfoComponent,
    AmazonComponent,

    // Components
    ConfirmationDesktopComponent,
    ConfirmationMobileComponent,
    BrandLandingDesktopComponent,
    BrandLandingMobileComponent,
    BrandSelectionDesktopComponent,
    BrandSelectionMobileComponent,
    PupSelectionDesktopComponent,
    PupSelectionMobileComponent,
    WaybillInfoMobileComponent,
    WaybillInfoDesktopComponent,
    WaybillConfirmationDesktopComponent,
    WaybillConfirmationMobileComponent,
    AddressInfoDesktopComponent,
    AddressInfoMobileComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,

    ReturnsRoutingModule,
    ReactiveFormsModule,

    Store,
    EffectsModule.forFeature([ReturnEffects, NavigationEffects]),
    MediaModule,
  ],
  providers: [WebConfigService, ReturnsApiService],
  exports: [],
})
export class ReturnsModule {}
