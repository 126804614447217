import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-brand-landing-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss'],
})
export class BrandLandingDesktopComponent {
  searchTerm = new FormControl();

  @Output() selectAddress = new EventEmitter();

  @Output() navigate = new EventEmitter();

  @Output() handleAutofillQuery = new EventEmitter();

  @Input() supplier: any;

  @Input() automaticSelection: any;

  @Input() autofillSuggestions$: any;

  @ViewChild('suggestionsElm') suggestionsElm!: ElementRef;

  constructor() {
    this.searchTerm.valueChanges.subscribe((value) => this.handleAutofillQuery.emit(value));
  }

  handleEnter(event: any) {
    // Only handle events from the search box
    if (event.target instanceof HTMLInputElement) {
      if (this.suggestionsElm !== undefined) {
        const suggestionsElm = this.suggestionsElm.nativeElement;

        // Find out if anything is selected from the suggestions list
        const selectedSuggestion = (Array.from(suggestionsElm.children) as HTMLDivElement[]).find(
          (elm: HTMLDivElement) => elm.classList.contains('selected'),
        );

        // If there is a selection use it as the search term's value
        if (selectedSuggestion) {
          this.searchTerm.setValue(selectedSuggestion.innerText.trim());
          this.selectAddress.emit(this.searchTerm.getRawValue());
        }
      }
    }

    return true;
  }

  handleArrows(event: any) {
    const isArrowUp = event.code === 'ArrowUp';
    const isArrowDown = event.code === 'ArrowDown';

    // Listen only for events on the search box when there are also suggestions
    if (HTMLInputElement && this.suggestionsElm !== undefined) {
      const suggestionsElm = this.suggestionsElm.nativeElement;

      // There can be other elements contained within the suggestion box that needs to be weeded out
      const children: HTMLElement[] = (Array.from(suggestionsElm.children) as HTMLElement[]).filter(
        (elm) => elm instanceof HTMLDivElement,
      );

      if (suggestionsElm.children.length > 0) {
        // Find out if there's a selected suggestion at the moment
        const selectedSuggestionIndex = children.findIndex((elm) =>
          elm.classList.contains('selected'),
        );

        if (selectedSuggestionIndex !== -1) {
          let destinationSibling = children[0];

          // The first two takes care of list wrapping, rest sequences the list
          if (selectedSuggestionIndex === 0 && isArrowUp) {
            destinationSibling = children[children.length - 1];
          } else if (selectedSuggestionIndex === children.length - 1 && isArrowDown) {
            destinationSibling = children[0];
          } else if (isArrowUp) {
            destinationSibling = children[selectedSuggestionIndex - 1];
          } else if (isArrowDown) {
            destinationSibling = children[selectedSuggestionIndex + 1];
          }

          // Swop around selected suggestions
          destinationSibling.classList.add('selected');
          children[selectedSuggestionIndex].classList.remove('selected');
        } else {
          // There are no suggestions selected, or we've run out of items in the list
          // Revert to using the first suggestion in the list
          for (let elm of Array.from(suggestionsElm.children) as HTMLDivElement[]) {
            elm.classList.remove('selected');
          }

          // Which direction are we taking to the address-train
          const initialChild = isArrowDown ? children[0] : children[children.length - 1];
          initialChild.classList.add('selected');
        }
      }
    }

    return true;
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: any) {
    if (event.code === 'Enter') {
      return this.handleEnter(event);
    }

    if (['ArrowUp', 'ArrowDown'].includes(event.code)) {
      return this.handleArrows(event);
    }

    return true;
  }
}
