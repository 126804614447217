<ng-container *ngIf="selectedSupplier$ | async as supplier">
  <ng-container *ngIf="supplier?.config; else loading">
    <use-media query="(max-width: 1023px)">
      <app-pup-selection-mobile
        (changeProcessType)="changeProcessType($event)"
        (goBack)="router.navigate(['/brand-landing'])"
        [supplier]="supplier"
        [processType]="processType"
        [automaticSelection]="automaticSelection"
        [searchedAddress]="searchAddress$ | async"
        [supplierPupSelectionConfig]="supplierPupSelectionConfig"
      ></app-pup-selection-mobile>
    </use-media>
    <use-media query="(min-width: 1024px)">
      <app-pup-selection-desktop
        (changeProcessType)="changeProcessType($event)"
        (goBack)="router.navigate(['/brand-landing'])"
        [supplier]="supplier"
        [processType]="processType"
        [automaticSelection]="automaticSelection"
        [searchedAddress]="searchAddress$ | async"
        [supplierPupSelectionConfig]="supplierPupSelectionConfig"
      ></app-pup-selection-desktop>
    </use-media>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="loader">
    <span>
      <span class="spinner spinner-slow" role="status"></span>
      &nbsp;Loading...
    </span>
  </div>
</ng-template>
