<header>
  <img
    alt="back"
    src="../../../../../../../assets/images/icons/ChevronLeft.svg"
    (click)="goBack.emit(pickupPoint.flow)"
  />
  <span class="title">{{ supplier.name }} Returns</span>
</header>

<div class="container">
  <div class="description">Complete your details to get your return parcel on the go.</div>

  <div class="return-details">
    <h3>Return Details</h3>

    <form [formGroup]="returnDetailsForm" autocomplete="off">
      <div class="form-group">
        <input
          type="text"
          formControlName="orderNumber"
          required
          placeholder=" "
          [class.invalid]="returnDetailsValidation.orderNumber"
        />
        <label>{{ supplier?.config?.referencePlaceholder }}</label>
        <span
          class="tooltip tooltip--left"
          data-tooltip="Your original order number provided by the store you purchased from"
        ></span>
      </div>
      <div class="form-group">
        <select
          formControlName="reasonForReturn"
          required
          placeholder=" "
          [class.dirty]="returnDetailsValidation.reasonForReturn"
        >
          <option disabled selected value></option>
          <option *ngFor="let reason of supplier.config?.returnReasonOptions" [ngValue]="reason">
            {{ reason }}
          </option>
        </select>
        <label>{{ supplier?.config?.returnReasonPlaceholder }}</label>
      </div>
      <div class="form-group">
        <textarea
          formControlName="additionalDetails"
          rows="2"
          placeholder=" "
          [class.invalid]="returnDetailsValidation.additionalDetails"
          (input)="updateCharacterCount()"
          [maxlength]="maxCharacters"
        ></textarea>
        <label>Additional details</label>
        <div class="character-limit">{{ characterCount }}/{{ maxCharacters }}</div>
      </div>
    </form>
    <div class="pargo-point" *ngIf="pickupPoint?.point">
      <h3>Pargo Point</h3>
      <div class="pargo-point-container">
        <label>{{ pickupPoint.point?.storeName }}</label>
        <address>
          <div class="left">
            <i class="pup-location"></i>
          </div>
          <div class="right">
            <span>
              {{ pickupPoint.point?.address1 }}{{ pickupPoint.point?.address2 ? ", " : ""
              }}{{ pickupPoint.point?.address2 }}{{ pickupPoint.point?.suburb ? ", " : "" }}
              {{ pickupPoint.point?.suburb }}{{ pickupPoint.point?.city ? ", " : "" }}
              {{ pickupPoint.point?.city }}{{ pickupPoint.point?.postalcode ? ", " : "" }}
              {{ pickupPoint.point?.postalcode }}
            </span>
          </div>
        </address>
        <button routerLink="/pup-selection">Reselect</button>
      </div>
    </div>
  </div>
  <div class="your-details">
    <h3>Your Details</h3>

    <form [formGroup]="yourDetailsForm" autocomplete="off">
      <div class="form-group">
        <input
          type="text"
          formControlName="firstName"
          required
          placeholder=" "
          [class.invalid]="yourDetailsValidation.firstName"
        />
        <label>First name</label>
      </div>
      <div class="form-group">
        <input
          type="text"
          formControlName="surname"
          required
          placeholder=" "
          [class.invalid]="yourDetailsValidation.surname"
        />
        <label>Surname</label>
      </div>
      <div class="form-group">
        <input
          type="email"
          formControlName="emailAddress"
          required
          placeholder=" "
          [class.invalid]="yourDetailsValidation.emailAddress"
        />
        <label>Email address</label>
      </div>
      <div class="form-group">
        <input
          type="tel"
          formControlName="mobileNumber"
          placeholder=" "
          [class.invalid]="yourDetailsValidation.mobileNumber"
        />
        <label>Mobile number</label>
      </div>
    </form>
    <p
      class="disclaimer"
      *ngIf="supplier.config?.returnPolicy && !supplier.config?.termsAndConditions"
    >
      By continuing, you agree to the respective brand's
      <a [href]="supplier.config?.returnPolicy" target="_blank">return policy</a>.
    </p>
    <p
      class="disclaimer"
      *ngIf="!supplier.config?.returnPolicy && supplier.config?.termsAndConditions"
    >
      By continuing, you agree to the respective brand's
      <a [href]="supplier.config?.termsAndConditions" target="_blank">terms and conditions</a>.
    </p>
    <p
      class="disclaimer"
      *ngIf="supplier.config?.returnPolicy && supplier.config?.termsAndConditions"
    >
      By continuing, you agree to the respective brand's
      <a [href]="supplier.config?.returnPolicy" target="_blank">return policy</a>
      and associated
      <a [href]="supplier.config?.termsAndConditions" target="_blank">terms and conditions</a>.
    </p>
  </div>
</div>
<footer>
  <button class="confirm-btn" (click)="handleConfirm.emit()">Continue</button>
</footer>
