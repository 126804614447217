import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-waybill-confirmation-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class WaybillConfirmationMobileComponent {
  @Output() handleConfirm = new EventEmitter();

  @Output() goBack = new EventEmitter();

  @Input() supplier: any;

  @Input() waybillDetails: any;

  @Input() pickupPoint: any;

  @Input() pickupAddress: any;
}
