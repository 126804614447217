import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectDropoff } from '../selectors/return';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { resetOrder } from '../actions/return';

@Injectable()
export class CanActivateConfirmed implements CanActivate {
  dropoff$ = this.store.select(selectDropoff);

  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.dropoff$.pipe(
      take(1),
      map((dropoff: any) => {
        if (dropoff && dropoff.created) {
          this.router.navigate(['/brand-landing']);
          this.store.dispatch(resetOrder());
          return false;
        }

        return true;
      }),
    );
  }
}
