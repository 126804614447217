<header>
  <span class="title"> {{ supplier.name }} Returns </span>

  <img [src]="supplier.config?.logo" *ngIf="supplier.config?.logo" [alt]="supplier.name" />
  <p>{{ supplier.config?.returnDescription }}</p>
</header>

<div class="content">
  <span class="info">Type in your address</span>

  <div class="search">
    <div class="form-group">
      <input
        type="search"
        [formControl]="searchTerm"
        placeholder=" "
        required
        minlength="3"
        autocomplete="false"
        [class.invalid]="searchTerm.touched && !searchTerm.valid"
      />
      <label>Your address</label>
      <i class="search-icon"></i>
    </div>
    <div class="suggestions" *ngIf="autofillSuggestions$ | async as suggestions">
      <div
        class="suggestion"
        *ngFor="let suggestion of suggestions"
        (click)="selectAddress.emit(suggestion.place_name)"
      >
        {{ suggestion.place_name }}
      </div>
    </div>
  </div>
</div>

<button class="back-btn" (click)="navigate.emit(['/'])" *ngIf="!automaticSelection">Back</button>
