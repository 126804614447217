import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class ConfirmationMobileComponent {
  @Output() download = new EventEmitter();

  @Input() supplier: any;

  @Input() pickupPoint: any;

  @Input() dropoff: any;

  @Input() waybillDetails: any;

  @Input() waybillValidation: any;

  @Input() pickupAddress: any;

  @Input() waybillType: any;

  showOverlay = false;

  toggleShow(): void {
    this.showOverlay = !this.showOverlay;
  }
}
