import { createSelector } from '@ngrx/store';

import { InitialState as ReturnState, LocationAddress } from '../reducers/return';

import { DropoffPoint, OrderDetails, Supplier, WaybillDetails } from '../models/suppliers';

export const returnState = (state: any): ReturnState => state.returns.return;

export const selectState = createSelector(returnState, (state): ReturnState => state);

export const selectSuppliers = createSelector(returnState, (state: ReturnState): Supplier[] => {
  return state?.suppliers || [];
});

export const selectSearchAddress = createSelector(
  returnState,
  (state: ReturnState): string | undefined => state.addressSearch,
);

export const selectOrderDetails = createSelector(
  returnState,
  (state: ReturnState): OrderDetails | undefined => state?.orderDetails,
);

export const selectWaybillType = createSelector(
  returnState,
  (state: ReturnState): Maybe<string> => state?.waybillType,
);

export const selectCurrentSupplier = createSelector(
  returnState,
  (state: ReturnState): Supplier | undefined => state?.currentSupplier,
);

export const selectGetSupplierFailed = createSelector(
  returnState,
  (state: ReturnState): boolean | undefined => state?.supplierFetchFailed,
);

export const selectDropoff = createSelector(
  returnState,
  (
    state: ReturnState,
  ):
    | {
        point?: DropoffPoint;
        expiry?: string;
        code?: string;
        created?: boolean;
        flow?: boolean;
      }
    | undefined => state?.dropoff,
);

export const selectPickup = createSelector(returnState, (state: ReturnState): any => state?.pickup);

export const selectWaybillValidation = createSelector(
  returnState,
  (
    state: ReturnState,
  ): {
    waybill?: string;
    validated?: boolean;
    waybillUrl?: string;
    expiry?: string;
  } => state?.waybillValidation,
);

export const selectOtpValidation = createSelector(
  returnState,
  (state: ReturnState): { otp?: string; validated?: boolean } => state?.otpValidation,
);

export const selectWaybillDetails = createSelector(
  returnState,
  (state: ReturnState): WaybillDetails | undefined => state?.waybillDetails,
);

export const selectLandedOnBrand = createSelector(
  returnState,
  (state: ReturnState): boolean => state.landedOnBrand,
);
