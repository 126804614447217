import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-info-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss'],
})
export class AddressInfoDesktopComponent implements OnInit {
  @Output() handleConfirm = new EventEmitter();

  @Output() goBack = new EventEmitter();

  @Input() supplier: any;

  @Input() pickupDetailsForm: any;

  @Input() pickupDetailsValidation: any;

  @Input() additionalDetailsForm: any;

  @Input() additionalDetailsValidation: any;

  readonly maxCharacters: number = 250;

  characterCount: number = 0;

  ngOnInit(): void {
    this.updateCharacterCount();
  }

  updateCharacterCount(): void {
    const additionalDetailsControl = this.additionalDetailsForm.get('additionalDetails');
    if (additionalDetailsControl) {
      this.characterCount = additionalDetailsControl?.value?.length | 0;
    }
  }
}
