<h2>ERROR</h2>
<p>Something went wrong.</p>
<p>
  Please make sure the correct URL is being used. <br />Alternatively,
  <a target="_blank" href="https://www.refreshyourcache.com/en/home/">clear your cache</a>
  and try again.
</p>
<p>
  <button [routerLink]="['/']">GO BACK</button>
</p>
