import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getSupplier, getSuppliers, resetReturn } from '../../actions/return';
import {
  selectCurrentSupplier,
  selectGetSupplierFailed,
  selectSuppliers,
} from '../../selectors/return';
import { UntypedFormControl } from '@angular/forms';
import Fuse from 'fuse.js';
import { Supplier } from '../../models/suppliers';

@Component({
  selector: 'app-brand-selection',
  templateUrl: './brand-selection.component.html',
  styleUrls: ['./brand-selection.component.scss'],
})
export class BrandSelectionComponent implements OnInit {
  readonly fetchSupplierFailed$ = this.store.select(selectGetSupplierFailed);

  constructor(private store: Store, private route: ActivatedRoute, private router: Router) {}

  searchTerm = new UntypedFormControl('');

  selectedSupplierId = '';

  readonly suppliers$ = this.store.select(selectSuppliers);

  readonly searchSuppliers$ = this.searchTerm.valueChanges.pipe(startWith('')).pipe(
    switchMap((value) =>
      this.suppliers$.pipe(
        map((suppliers) => {
          if (!value) {
            return {
              suppliers: suppliers.slice(0, 5),
              isLoading: suppliers.length === 0,
            };
          }

          this.selectedSupplierId = '';

          const fuse = new Fuse(suppliers, {
            keys: ['name'],
            threshold: 0.4,
          });
          const search = fuse.search(value);

          return {
            suppliers: search.map((item) => item.item).slice(0, 5),
            isLoading: false,
          };
        }),
        startWith({ isLoading: true, suppliers: null }),
      ),
    ),
  );

  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier).pipe(
    map((value) => ({
      isLoading: !value?.name,
      value: value?.name ? value : false,
    })),
    startWith({ isLoading: true, value: false }),
  );

  changeSelectedSupplier(supplier?: Supplier): void {
    if (supplier) {
      this.selectedSupplierId = supplier.id;
      this.store.dispatch(getSupplier(supplier));
    } else {
      this.selectedSupplierId = '';
    }
  }

  bookReturn(): void {
    this.router.navigate(['/brand-landing']);
  }

  ngOnInit(): void {
    this.store.dispatch(getSuppliers());
    this.store.dispatch(resetReturn());
  }
}
