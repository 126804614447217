import { OnInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-waybill-info-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss'],
})
export class WaybillInfoDesktopComponent implements OnInit {
  @Output() handleConfirm = new EventEmitter();

  @Output() goBack = new EventEmitter();

  @Input() supplier: any;

  @Input() pickupPoint: any;

  @Input() pickupAddress: any;

  @Input() returnDetailsForm: any;

  @Input() returnDetailsValidation: any;

  @Input() yourDetailsForm: any;

  @Input() yourDetailsValidation: any;

  readonly maxCharacters: number = 250;

  characterCount: number = 0;

  ngOnInit(): void {
    this.updateCharacterCount();
  }

  updateCharacterCount(): void {
    const returnDetailsControl = this.returnDetailsForm.get('additionalDetails');
    if (returnDetailsControl) {
      this.characterCount = returnDetailsControl?.value?.length | 0;
    }
  }
}
