<header>
  <img alt="back" src="../../../../../../../assets/images/icons/ChevronLeft.svg" (click)="goBack.emit()" />
  <span class="title">{{ supplier.name }} Returns</span>
</header>

<div class="container">
  <div class="description">
    You're almost done! Simply confirm your details here to process your return.
  </div>

  <div class="return-details">
    <h3>Return Details</h3>

    <div>
      <label>{{ supplier?.config?.referencePlaceholder }}</label>
      {{ waybillDetails.returnDetails.orderNumber }}
    </div>
    <div>
      <label>{{ supplier?.config?.returnReasonPlaceholder }}</label>
      {{ waybillDetails.returnDetails.reasonForReturn }}
    </div>
    <div>
      <label>Additional Details</label>
      {{ waybillDetails.returnDetails.additionalDetails }}
    </div>
  </div>
  <div class="your-details">
    <h3>Your Details</h3>
    <div>
      <label>Full name</label>
      {{ waybillDetails.yourDetails.firstName }}
      {{ waybillDetails.yourDetails.surname }}
    </div>
    <div>
      <label>Email address</label>
      {{ waybillDetails.yourDetails.emailAddress }}
    </div>
    <div>
      <label>Mobile number</label>
      {{ waybillDetails.yourDetails.mobileNumber }}
    </div>
  </div>
  <div class="pargo-point">
    <div class="pargo-point-container" *ngIf="pickupPoint && pickupPoint?.point">
      <h3>Pargo Point</h3>
      <label>{{ pickupPoint.point?.storeName }}</label>
      <address>
        <span class="pup-icon"></span>
        <span>
          {{ pickupPoint.point?.address1 }}{{ pickupPoint.point?.address2 ? ', ' : ''}}
          {{ pickupPoint.point?.address2 }}{{ pickupPoint.point?.suburb ? ', ' : ''}}
          {{ pickupPoint.point?.suburb }}{{ pickupPoint.point?.city ? ', ' : ''}}
          {{ pickupPoint.point?.city }}{{ pickupPoint.point?.postalcode ? ', ' : ''}}
          {{ pickupPoint.point?.postalcode }}
        </span>
      </address>
    </div>
    <div class="pargo-point-container" *ngIf="!(pickupPoint && pickupPoint?.point) && pickupAddress">
      <h3>Pick Up Address</h3>
      <label>{{ pickupAddress.address1 }}</label>
      <address>
        <span class="pup-icon"></span>
        <span>
          {{ pickupAddress?.address1 }}{{ pickupAddress?.address2 ? ', ' : '' }}
          {{ pickupAddress?.address2 }}{{ pickupAddress?.suburb ? ', ' : '' }}
          {{ pickupAddress?.suburb }}{{ pickupAddress?.city ? ', ' : '' }}
          {{ pickupAddress?.city }}{{ pickupAddress?.postalcode ? ', ' : '' }}
          {{ pickupAddress.postalcode }}
        </span>
      </address>
    </div>
  </div>
</div>
<footer>
  <button class="confirm-btn" (click)="handleConfirm.emit()">Confirm</button>
</footer>
