<div class="header">
  <h1>
    {{ supplier.name }} Returns
    <img [src]="supplier.config?.logo" *ngIf="supplier.config?.logo" [alt]="supplier.name" />
  </h1>
</div>

<div class="container">
  <ng-container *ngIf="dropoff as data">
    <ng-container *ngIf="data.isLoading">
      <span>
        <span class="spinner spinner-slow" role="status"></span>
        &nbsp;Logging return...
      </span>
    </ng-container>
    <ng-container *ngIf="data.value as dropoff">
      <span class="info" *ngIf="supplier.config?.needsGreenLight"
        >Hi {{ waybillDetails?.yourDetails?.firstName }}, your return has been logged with
        {{ supplier.name }} .</span
      >
      <span class="info" *ngIf="!supplier.config?.needsGreenLight"
        >Hi {{ waybillDetails?.yourDetails?.firstName }}, your return is confirmed</span
      >

      <!-- Door - Pending -->
      <ng-container *ngIf="!supplier.config?.needsGreenLight && waybillType === 'D2W'">
        <div class="block-container">
          <div class="block">
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                  Package your Return
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>
                  Securely package your return parcel and remove all old labels and waybills from
                  the parcel.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                  GET READY
                </h3>
                <img src="/assets/images/returns/mobile.svg" alt="" />
                <p>
                  Wait for confirmation via SMS and email. Keep an eye on your inbox for next
                  instructions.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                  HAND OVER PARCEL
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Be ready to hand over your return parcel to our couriers when they arrive.</p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="inline full-height" [class.no-link]="!supplier.config?.shopLink">
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>GET READY!</h2>
              <p>
                Our couriers have been notified to collect your {{ supplier.name }} return parcel.
                Keep an eye on your inbox for updates.
              </p>
            </div>

            <button
              *ngIf="supplier.config?.shopLink"
              (click)="download.emit(supplier.config?.shopLink)"
            >
              Shop at {{ supplier.name }}
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Door - Confirmed -->
      <ng-container *ngIf="supplier.config?.needsGreenLight && waybillType === 'D2W'">
        <div class="block-container">
          <div class="block">
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                  Package your Return
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>
                  Securely package your return parcel and remove all old labels and waybills from
                  the parcel.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                  AWAIT COMMUNICATION
                </h3>
                <img src="/assets/images/returns/mobile.svg" alt="" />
                <p>
                  Keep an eye on your inbox for further instruction from Pargo via SMS and email.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                  HAND OVER PARCEL
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Be ready to hand over your return parcel to our couriers when they arrive.</p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="inline full-height" [class.no-link]="!supplier.config?.shopLink">
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>ALMOST READY!</h2>
              <p>
                Keep an eye on your inbox for confirmation from {{ supplier.name }} with next steps
                in getting your return parcel on the go.
              </p>
            </div>

            <button
              *ngIf="supplier.config?.shopLink"
              (click)="download.emit(supplier.config?.shopLink)"
            >
              Shop at {{ supplier.name }}
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Paperless Pending -->
      <ng-container
        *ngIf="waybillType !== 'D2W' && supplier.config?.needsGreenLight && dropoff.flow"
      >
        <div class="block-container">
          <div class="block">
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                  Return Confirmation
                </h3>
                <img src="/assets/images/returns/clock.svg" alt="" />
                <p>{{ supplier.name }} will notify you once they've confirmed your return.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                  Package your return
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>Prepare your parcel making sure to remove all old labels and stickers.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                  Drop-off code
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>
                  You'll need your unique drop off code when you visit your selected Pargo Point.
                </p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="inline full-height" [class.no-link]="!supplier.config?.shopLink">
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Confirmation Pending</h2>
              <p>
                Look out for {{ supplier.name }}'s confirmation email with your drop-off code and
                next steps in getting your parcel on the go.
              </p>
            </div>

            <button
              *ngIf="supplier.config?.shopLink"
              (click)="download.emit(supplier.config?.shopLink)"
            >
              Shop at {{ supplier.name }}
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Print Pending -->
      <ng-container
        *ngIf="waybillType !== 'D2W' && supplier.config?.needsGreenLight && !dropoff.flow"
      >
        <div class="block-container">
          <div class="block">
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                  Return Confirmation
                </h3>
                <img src="/assets/images/returns/clock.svg" alt="" />
                <p>{{ supplier.name }} will notify you once they've confirmed your return.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                  Package your return
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>
                  Print your label and prepare your parcel making sure to remove all old labels and
                  stickers.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                  Drop off your parcel
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Drop off your parcel at your selected Pargo Point.</p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="inline full-height" [class.no-link]="!supplier.config?.shopLink">
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Confirmation Pending</h2>
              <p>
                Look out for {{ supplier.name }}'s confirmation email with next steps in getting
                your parcel on the go.
              </p>
            </div>

            <button
              *ngIf="supplier.config?.shopLink"
              (click)="download.emit(supplier.config?.shopLink)"
            >
              Shop at {{ supplier.name }}
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Paperless Confirmed -->
      <ng-container
        *ngIf="waybillType !== 'D2W' && !supplier.config?.needsGreenLight && dropoff.flow"
      >
        <div class="block-container">
          <div class="block">
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                  Drop-off code
                </h3>
                <img src="/assets/images/returns/clock.svg" alt="" />
                <p>You'll need this code to drop off your return at your selected Pargo Point.</p>
                <span class="drop-off-code">{{ dropoff.code }}</span>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                  Visit the store
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>Prepare your parcel making sure to remove all old labels and stickers</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                  Drop off your parcel
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Drop off your parcel at your selected Pargo Point.</p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="inline">
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Return Confirmed</h2>
            </div>

            <div class="drop-off-container">
              <span>Drop-off code</span>
              <div>
                {{ dropoff.code }}
              </div>

              <span>Pargo Point</span>
              <div>
                {{ pickupPoint.point?.storeName }}
              </div>

              <span>Address</span>
              <div>
                {{ pickupPoint.point?.address1 }},
                {{ pickupPoint.point?.address2 }}
              </div>

              <span>Drop-off Expiry</span>
              <div>
                {{ dropoff.expiry * 1000 | date: "medium" }}
              </div>
            </div>

            <button
              *ngIf="supplier.config?.shopLink"
              (click)="download.emit(supplier.config?.shopLink)"
            >
              Shop at {{ supplier.name }}
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Print Confirmed -->
      <ng-container
        *ngIf="waybillType !== 'D2W' && !supplier.config?.needsGreenLight && !dropoff.flow"
      >
        <div class="block-container">
          <div class="block">
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                  Print your label
                </h3>
                <img src="/assets/images/returns/print-screen.svg" alt="" />
                <p>Print and secure the label to your parcel where it can clearly be seen.</p>
                <button class="brand-btn" (click)="download.emit(waybillValidation.waybillUrl)">
                  Download to Print
                </button>
                <small> Your return details have also been sent to you via email and SMS. </small>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                  Package your return
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>Prepare your parcel making sure to remove all old labels and stickers.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                  Drop off your parcel
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Drop off your parcel at your selected Pargo Point.</p>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="inline">
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Return Confirmed</h2>
            </div>

            <div class="drop-off-container">
              <span>Pargo Point</span>
              <div>
                {{ pickupPoint.point?.storeName }}
              </div>

              <span>Address</span>
              <div>
                {{ pickupPoint.point?.address1 }},
                {{ pickupPoint.point?.address2 }}
              </div>

              <span>Drop-off Expiry</span>
              <div>
                {{ dropoff.expiry * 1000 | date: "medium" }}
              </div>
            </div>

            <button
              *ngIf="supplier.config?.shopLink"
              (click)="download.emit(supplier.config?.shopLink)"
            >
              Shop at {{ supplier.name }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
