import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PupSelectionConfig, Supplier } from '../../../../models/suppliers';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../../../../../environments/environment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pup-selection-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PupSelectionDesktopComponent {
  @Output() changeProcessType = new EventEmitter();

  @Output() goBack = new EventEmitter();

  @Input() supplier: any;

  @Input() processType = 'all';

  @Input() automaticSelection: any;

  @Input() searchedAddress: any;

  processTypeControl = new FormControl();

  @Input() supplierPupSelectionConfig: PupSelectionConfig;

  constructor(private sanitizer: DomSanitizer) {
    this.processTypeControl.setValue(this.processType);
  }

  mapSource(supplier: Supplier, processType: string): SafeUrl {
    const mapTokens: Record<string, string | undefined> = {
      print: supplier.config?.mapTokenPrint,
      paperless: supplier.config?.mapTokenPaperless,
      all: supplier.config?.mapTokenAllPickupPoints,
    };

    const token = processType in mapTokens ? mapTokens[processType] : environment.defaultMapToken;

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.defaultMapApi}/?token=${token}&address=${this.searchedAddress}`,
    );
  }
}
