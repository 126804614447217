<header>
  <img
    alt="back"
    src="../../../../../../../assets/images/icons/ChevronLeft.svg"
    *ngIf="selectedSupplierId"
    (click)="changeSelectedSupplier.emit(null)"
  />
  <span class="title">Choose Brand</span>
  <img src="/assets/images/logo.svg" alt="Pargo" class="logo" />
</header>

<div class="content">
  <ng-container *ngIf="!selectedSupplierId">
    <div class="search-container">
      <ng-container *ngIf="searchSuppliers$ | async as data">
        <div class="form-group">
          <input type="search" [formControl]="searchTerm" placeholder=" " />
          <label>Search for brand</label>
          <i class="search-icon"></i>
        </div>
        <ng-container *ngIf="data.suppliers as suppliers">
          <ul class="supplier-list">
            <li
              *ngFor="let supplier of suppliers"
              [class.selected]="selectedSupplierId === supplier.id"
              (click)="changeSelectedSupplier.emit(supplier)"
            >
              <span class="text">{{ supplier.name }}</span>
              <img class="icon" src="/assets/images/icons/ChevronLeft.svg" />
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="data.isLoading">
          <br />
          <span>
            <span class="spinner spinner-slow" role="status"></span>
            &nbsp;Loading...
          </span>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedSupplierId">
    <div class="selected-supplier-container">
      <ng-container *ngIf="selectedSupplier$ | async as data">
        <ng-container *ngIf="data.value as supplier">
          <div class="selected-supplier">
            <img
              [src]="supplier.config?.logo"
              *ngIf="supplier.config?.logo"
              [alt]="supplier.name"
            />
            <h1>{{ supplier.name }} Returns</h1>
            <p class="description">
              {{ supplier.config?.returnDescription }}
            </p>
            <button (click)="bookReturn.emit(supplier)">Log a Return</button>
          </div>
        </ng-container>
        <ng-container *ngIf="data.isLoading">
          <br />
          <span>
            <span class="spinner spinner-slow" role="status"></span>
            &nbsp;Loading...
          </span>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
