import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';

import { InitialState } from '../reducers/return';
import { ReturnsApiService } from '../services/returns-api.service';

import {
  ADD_SUPPLIERS,
  CREATE_RETURN,
  CREATING_RETURN_FAILED,
  GET_SUPPLIER,
  GET_SUPPLIER_FAILED,
  GET_SUPPLIERS,
  NAVIGATE_TO_STEP,
  RESET_RETURN,
  SET_CODE,
  SET_CREATED,
  SET_CURRENT_SUPPLIER,
  SET_DROP_OFF_FLOW,
  SET_EXPIRY,
  SET_NEEDS_GREEN_LIGHT,
  SET_SEARCH_ADDRESS,
  SET_WAYBILL,
  SET_WAYBILL_TYPE,
  SET_WAYBILL_URL,
  VALIDATE_OTP,
  VALIDATE_OTP_FAILED,
  VALIDATE_WAYBILL,
  VALIDATE_WAYBILL_FAILED,
  VALIDATED_OTP,
  VALIDATED_WAYBILL,
} from '../actions/return';

import { selectState } from '../selectors/return';
import { NAVIGATE } from '../actions/navigation';

@Injectable()
export class ReturnEffects {
  public getSuppliers$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(GET_SUPPLIERS),
      concatMap(() => from(this.returnsApiService.getSuppliers())),
      concatMap((suppliers) => {
        return [{ type: ADD_SUPPLIERS, suppliers }];
      }),
      catchError(() => {
        return [{ type: GET_SUPPLIER_FAILED }];
      }),
    ),
  );

  public getSupplier$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(GET_SUPPLIER),
      concatMap(({ id }) => from(this.returnsApiService.getSupplier(id))),
      concatMap((result: { success: boolean; data: any }) => {
        if (result && result.success) {
          const { data } = result;

          return [{ type: SET_CURRENT_SUPPLIER, supplier: data }];
        }

        return [{ type: GET_SUPPLIER_FAILED }];
      }),
      catchError(() => {
        return [{ type: GET_SUPPLIER_FAILED }];
      }),
    ),
  );

  public validateWaybill$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(VALIDATE_WAYBILL),
      concatMap(({ waybill }) => {
        return from(
          this.returnsApiService.validateWaybill(waybill).pipe(
            catchError(() => {
              return [{ type: VALIDATE_WAYBILL_FAILED }];
            }),
          ),
        );
      }),
      concatMap((result: { success: boolean; data: any }) => {
        if (result && result.success) {
          const { data } = result;

          return [
            {
              type: VALIDATED_WAYBILL,
              validated: true,
              orderDetails: data,
            },
            { type: NAVIGATE_TO_STEP, step: 4 },
          ];
        }

        return [{ type: VALIDATE_WAYBILL_FAILED }];
      }),
    ),
  );

  public validateOtp$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(VALIDATE_OTP),
      concatMap(({ otp }) => from(this.returnsApiService.validateOtp(otp))),
      concatMap((result: { success: boolean; data: any }) => {
        if (result && result.success) {
          const { data } = result;
          return [
            { type: VALIDATED_OTP, validated: data.valid },
            data.valid && { type: CREATE_RETURN },
          ].filter((x) => x);
        }
        return [{ type: VALIDATE_OTP_FAILED }];
      }),
    ),
  );

  public createReturn$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(CREATE_RETURN),
      withLatestFrom(this.store.select(selectState)),
      concatMap((props) => {
        const state: InitialState = props[1];

        const { currentSupplier, dropoff, waybillDetails, pickup } = state;

        const fromAddress = {
          address_1: pickup?.address1,
          address_2: pickup?.address2,
          suburb: pickup?.suburb,
          city: pickup?.city,
          province: pickup?.province,
          postal_code: pickup?.postalcode,
          country: pickup?.country,
        };

        const order: any = {
          data: {
            supplier_id: currentSupplier?.id,
            pickup_point_reference: dropoff?.point?.pargoPointCode,
            package_description: waybillDetails?.returnDetails.reasonForReturn,
            external_reference: waybillDetails?.returnDetails.orderNumber,
            additional_details: waybillDetails?.returnDetails.additionalDetails,
            consignee: {
              first_name: waybillDetails?.yourDetails.firstName,
              last_name: waybillDetails?.yourDetails.surname,
              phone_numbers: [waybillDetails?.yourDetails.mobileNumber],
              email: waybillDetails?.yourDetails.emailAddress,
            },
          },
        };

        if (!dropoff?.point) {
          order.data.from = fromAddress;
        }

        return from(this.returnsApiService.createReturn(order));
      }),
      concatMap((result: { success: boolean; data: any }) => {
        if (result && result.success) {
          const { stage_name, type } = result.data;

          if (stage_name === 'pending') {
            const { trackingCode, drop_off_code_flow } = result.data;

            return [
              { type: SET_NEEDS_GREEN_LIGHT, greenLight: true },
              { type: SET_CREATED, created: true },
              { type: SET_WAYBILL, waybill: trackingCode },
              { type: SET_WAYBILL_TYPE, waybillType: type },
              {
                type: SET_DROP_OFF_FLOW,
                flow: drop_off_code_flow,
              },
            ];
          } else {
            const { expiry, waybill_link, trackingCode, drop_off_code, drop_off_code_flow } =
              result.data;

            return [
              { type: SET_NEEDS_GREEN_LIGHT, greenLight: false },
              { type: SET_CREATED, created: true },
              { type: SET_WAYBILL_TYPE, waybillType: type },
              { type: SET_EXPIRY, expiry },
              { type: SET_WAYBILL, waybill: trackingCode },
              { type: SET_WAYBILL_URL, waybillUrl: waybill_link },
              { type: SET_CODE, code: drop_off_code },
              {
                type: SET_DROP_OFF_FLOW,
                flow: drop_off_code_flow,
              },
            ];
          }
        }

        return [{ type: CREATING_RETURN_FAILED }, { type: SET_CREATED, created: false }];
      }),
    ),
  );

  public resetReturn$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(RESET_RETURN),
      concatMap(() => {
        return [{ type: NAVIGATE, url: ['/'] }];
      }),
    ),
  );

  public searchAddress$: any = createEffect(() =>
    this.actions.pipe(
      ofType(SET_SEARCH_ADDRESS),
      concatMap(() => {
        return [{ type: NAVIGATE, url: ['/pup-selection'] }];
      }),
    ),
  );

  constructor(
    private actions: Actions<any>,
    private returnsApiService: ReturnsApiService,
    private store: Store<InitialState>,
  ) {}
}
