import { Action, createReducer, on } from '@ngrx/store';

import { DropoffPoint, OrderDetails, Pickup, Supplier, WaybillDetails } from '../models/suppliers';

import { actions } from '../actions/return';

export interface LocationAddress {
  city: string;
  country: string;
  postalcode: string;
  suburb: string;
  province: string;
  address: string;
}

export interface InitialState {
  suppliers: Supplier[] | undefined;
  currentSupplier: Supplier | undefined;
  supplierFetchFailed?: boolean;
  orderDetails?: OrderDetails;
  printer?: boolean;
  waybillValidation: {
    waybill?: string;
    validated?: boolean;
    waybillUrl?: string;
  };
  otpValidation: {
    otp?: string;
    validated?: boolean;
  };
  dropoff: {
    point?: DropoffPoint;
    expiry?: string;
    code?: string;
    created: boolean;
    flow?: boolean;
  };
  pickup: Pickup | undefined;
  waybillDetails: WaybillDetails | undefined;
  error: string | undefined;
  landedOnBrand: boolean;
  addressSearch: string;
  waybillType: string;
}

export const initialState: InitialState = {
  suppliers: undefined,
  currentSupplier: undefined,
  orderDetails: undefined,
  supplierFetchFailed: undefined,
  printer: undefined,
  addressSearch: undefined,
  waybillValidation: {
    waybill: undefined,
    validated: undefined,
    waybillUrl: undefined,
  },
  otpValidation: {
    otp: undefined,
    validated: undefined,
  },
  dropoff: {
    point: undefined,
    expiry: undefined,
    code: undefined,
    created: false,
    flow: undefined,
  },
  pickup: undefined,
  waybillDetails: undefined,
  waybillType: undefined,
  error: undefined,
  landedOnBrand: false,
};

export const returnReducerKey = 'return';

const reducer = createReducer(
  initialState,
  on(
    actions.setSearchAddress,
    (state: InitialState, { address }): InitialState => ({
      ...state,
      addressSearch: address,
    }),
  ),
  on(
    actions.setWaybillType,
    (state: InitialState, { waybillType }): InitialState => ({
      ...state,
      waybillType: waybillType,
    }),
  ),
  on(
    actions.setPickup,
    (state: InitialState, { pickup }): InitialState => ({
      ...state,
      pickup: pickup,
    }),
  ),
  on(actions.addSuppliers, (state: InitialState, { suppliers }): InitialState => {
    return {
      ...state,
      suppliers: suppliers.map((supplier) => {
        const { data } = supplier;
        return data;
      }),
    };
  }),
  on(actions.getSupplier, (state: InitialState, { id }): InitialState => {
    const currentSupplier = {
      ...state.currentSupplier,
      id,
      name: '',
    };

    return {
      ...state,
      currentSupplier,
    };
  }),

  on(
    actions.resetCurrentSupplier,
    (state: InitialState): InitialState => ({
      ...state,
      currentSupplier: undefined,
    }),
  ),

  on(actions.setCurrentSupplier, (state: InitialState, { supplier }): InitialState => {
    const currentSupplier = {
      ...state.currentSupplier,
      id: supplier.id || state.currentSupplier?.id || '',
      name: supplier.displayName,
      config: {
        ...state.currentSupplier?.config,
        logo: supplier.logo,
        termsAndConditions: supplier.termsAndConditions,
        returnPolicy: supplier.returnPolicy,
        returnReasonPlaceholder: supplier.descriptionName || 'Reason for return',
        referencePlaceholder: supplier.externalReferenceName || 'Original Order number',
        shopLink: supplier.shopLink,
        returnDescription: supplier.returnDescription,
        mapTokenPrint: supplier.mapTokenPrint,
        mapTokenPaperless: supplier.mapTokenPaperless,
        mapTokenAllPickupPoints: supplier.mapTokenAllPickupPoints,
        returnReasonOptions: supplier.returnReasonOptions || [],
        paperlessCount: supplier.paperlessCount,
        printCount: supplier.printCount,
        allPickupPointsCount: supplier.allPickupPointsCount,
        returnsFromDoorEnabled: supplier.returnsFromDoorEnabled,
      },
    };

    return {
      ...state,
      currentSupplier,
    };
  }),
  on(actions.setOrderDetails, (state: InitialState, { orderDetails }): InitialState => {
    return {
      ...state,
      orderDetails,
    };
  }),
  on(actions.setPrinter, (state: InitialState, { printer }): InitialState => {
    return {
      ...state,
      printer,
    };
  }),

  on(actions.setWaybill, (state: InitialState, { waybill }): InitialState => {
    return {
      ...state,
      waybillValidation: {
        ...state.waybillValidation,
        waybill,
      },
    };
  }),

  on(actions.validatedWaybill, (state: InitialState, { validated, orderDetails }): InitialState => {
    return {
      ...state,
      orderDetails: {
        firstName: orderDetails.first_name,
        lastName: orderDetails.last_name,
        email: orderDetails.email,
        phoneNumber: orderDetails.phone_numbers[0],
      },
      waybillValidation: {
        ...state.waybillValidation,
        validated,
      },
    };
  }),

  on(actions.setWaybillUrl, (state: InitialState, { waybillUrl }): InitialState => {
    return {
      ...state,
      waybillValidation: {
        ...state.waybillValidation,
        waybillUrl,
      },
    };
  }),

  on(actions.getSupplierFailed, (state: InitialState): InitialState => {
    return {
      ...state,
      supplierFetchFailed: true,
    };
  }),

  on(actions.validateWaybillFailed, (state: InitialState): InitialState => {
    return {
      ...state,
      waybillValidation: {
        waybill: undefined,
        validated: false,
      },
    };
  }),

  on(actions.resetValidateWaybill, (state: InitialState): InitialState => {
    return {
      ...state,
      waybillValidation: {
        waybill: undefined,
        validated: undefined,
      },
    };
  }),

  on(actions.setNeedsGreenLight, (state: InitialState, { greenLight }): InitialState => {
    const currentSupplier = {
      ...state.currentSupplier,
      id: state.currentSupplier?.id || '',
      name: state.currentSupplier?.name || '',
      config: {
        ...state.currentSupplier?.config,
        needsGreenLight: greenLight,
      },
    };
    return {
      ...state,
      currentSupplier,
    };
  }),

  on(actions.validateOtp, (state: InitialState, { otp }): InitialState => {
    return {
      ...state,
      otpValidation: {
        ...state.otpValidation,
        otp,
      },
    };
  }),

  on(actions.validatedOtp, (state: InitialState, { validated }): InitialState => {
    return {
      ...state,
      otpValidation: {
        ...state.otpValidation,
        validated,
      },
    };
  }),

  on(actions.validateOtpFailed, (state: InitialState): InitialState => {
    return {
      ...state,
      otpValidation: {
        otp: undefined,
        validated: false,
      },
    };
  }),

  on(actions.setDropoffPoint, (state: InitialState, { dropoffPoint }): InitialState => {
    return {
      ...state,
      dropoff: {
        ...state.dropoff,
        point: dropoffPoint,
      },
    };
  }),

  on(actions.resetDropoffPoint, (state: InitialState): InitialState => {
    return {
      ...state,
      dropoff: {
        ...state.dropoff,
        point: undefined,
      },
    };
  }),

  on(actions.setDropOffFlow, (state: InitialState, { flow }): InitialState => {
    return {
      ...state,
      dropoff: {
        ...state.dropoff,
        flow,
      },
    };
  }),

  on(actions.setExpiry, (state: InitialState, { expiry }): InitialState => {
    return {
      ...state,
      dropoff: {
        ...state.dropoff,
        expiry,
      },
    };
  }),

  on(actions.setCode, (state: InitialState, { code }): InitialState => {
    return {
      ...state,
      dropoff: {
        ...state.dropoff,
        code,
      },
    };
  }),

  on(actions.setCreated, (state: InitialState, { created }): InitialState => {
    return {
      ...state,
      dropoff: {
        ...state.dropoff,
        created,
      },
    };
  }),

  on(actions.resetOrder, (state: InitialState): InitialState => {
    return {
      ...initialState,
      currentSupplier: state.currentSupplier,
      landedOnBrand: state.landedOnBrand,
      suppliers: state.suppliers,
    };
  }),

  on(actions.resetReturn, (state: InitialState): InitialState => {
    return {
      ...initialState,
      suppliers: state.suppliers,
    };
  }),

  on(
    actions.setWaybillDetails,
    (state: InitialState, { waybillDetails }): InitialState => ({
      ...state,
      waybillDetails,
    }),
  ),

  on(
    actions.setLandedOnBrand,
    (state: InitialState, { landed }): InitialState => ({
      ...state,
      landedOnBrand: landed,
    }),
  ),
);

export function returnReducer(state: InitialState, action: Action): InitialState {
  return reducer(state, action);
}
