import { createAction, props } from '@ngrx/store';

import { DropoffPoint, OrderDetails, Pickup, WaybillDetails } from '../models/suppliers';

export const INIT_APPLICATION = 'RETURN/INIT_APPLICATION';
export const NAVIGATE_TO_STEP = 'RETURN/NAVIGATE_TO_STEP';
export const UPDATE_SKIPPED_STEPS = 'RETURN/UPDATE_SKIPPED_STEPS';
export const RESET_CURRENT_SUPPLIER = 'RETURN/RESET_CURRENT_SUPPLIER';
export const RESET_ORDER = 'RETURN/RESET_ORDER';

export const GET_SUPPLIERS = 'RETURN/GET_SUPPLIERS';
export const GET_SUPPLIER = 'RETURN/GET_SUPPLIER';
export const ADD_SUPPLIERS = 'RETURN/ADD_SUPPLIERS';
export const GET_SUPPLIER_FAILED = 'RETURN/GET_SUPPLIER_FAILED';

export const SET_CURRENT_SUPPLIER = 'RETURN/SET_CURRENT_SUPPLIER';
export const SET_ORDER_DETAILS = 'RETURN/SET_ORDER_DETAILS';
export const SET_PRINTER = 'RETURN_SET_PRINTER';
export const SET_DROPOFF_POINT = 'RETURN/SET_DROPOFF_POINT';
export const RESET_DROPOFF_POINT = 'RETURN/RESET_DROPOFF_POINT';
export const SET_WAYBILL = 'RETURN/SET_WAYBILL';
export const SET_WAYBILL_TYPE = 'RETURN/SET_WAYBILL_TYPE';
export const SET_EXPIRY = 'RETURN/SET_EXPIRY';
export const SET_CODE = 'RETURN/SET_CODE';
export const SET_WAYBILL_URL = 'RETURN/SET_WAYBILL_URL';
export const SET_CREATED = 'RETURN/SET_CREATED';
export const SET_DROP_OFF_FLOW = 'RETURN/SET_DROP_OFF_FLOW';

export const VALIDATE_WAYBILL = 'RETURN/VALIDATE_WAYBILL';
export const VALIDATED_WAYBILL = 'RETURN/VALIDATED_WAYBILL';
export const VALIDATE_WAYBILL_FAILED = 'RETURN/VALIDATE_WAYBILL_FAILED';
export const RESET_VALIDATE_WAYBILL = 'RETURN/RESET_VALIDATE_WAYBILL';

export const VALIDATE_OTP = 'RETURN/VALIDATE_OTP';
export const VALIDATED_OTP = 'RETURN/VALIDATED_OTP';
export const VALIDATE_OTP_FAILED = 'RETURN/VALIDATE_OTP_FAILED';

export const CREATE_RETURN = 'RETURN/CREATE_RETURN';
export const RETURN_CREATED = 'RETURN/RETURN_CREATED';
export const CREATING_RETURN_FAILED = 'RETURN/CREATING_RETURN_FAILED';

export const SET_NEEDS_GREEN_LIGHT = 'RETURN/SET_NEEDS_GREEN_LIGHT';

export const RESET_RETURN = 'RETURN/RESET_RETURN';

export const SET_WAYBILL_DETAILS = 'RETURN/SET_WAYBILL_DETAILS';

export const SET_LANDED_ON_BRAND = 'RETURN/SET_LANDED_ON_BRAND';
export const SET_SEARCH_ADDRESS = 'RETURN/SET_SEARCH_ADDRESS';
export const SET_PICKUP = 'RETURN/SET_PICKUP';

export const initApplication = createAction(INIT_APPLICATION);

export const setLandedOnBrand = createAction(NAVIGATE_TO_STEP, props<{ landed: boolean }>());
export const setSearchAddress = createAction(SET_SEARCH_ADDRESS, props<{ address: string }>());

export const clear = createAction(SET_SEARCH_ADDRESS, props<{ address: string }>());

export const navigateToStep = createAction(
  NAVIGATE_TO_STEP,
  props<{ step: number; previousStep?: number }>(),
);

export const updateSkippedSteps = createAction(
  UPDATE_SKIPPED_STEPS,
  props<{ skippedSteps: number[] }>(),
);

export const resetCurrentSupplier = createAction(RESET_CURRENT_SUPPLIER);

export const getSuppliers = createAction(GET_SUPPLIERS);

export const getSupplier = createAction(GET_SUPPLIER, props<{ id: string }>());

export const addSuppliers = createAction(
  ADD_SUPPLIERS,
  props<{ suppliers: { data: { id: string; name: string } }[] }>(),
);

export const setCurrentSupplier = createAction(
  SET_CURRENT_SUPPLIER,
  props<{
    supplier: {
      id: string;
      displayName: string;
      externalReferenceName: string;
      descriptionName: string;
      returnPolicy: string;
      termsAndConditions: string;
      logo: string;
      shopLink: string;
      returnDescription: string;
      mapTokenPrint: string;
      mapTokenPaperless: string;
      mapTokenAllPickupPoints: string;
      returnReasonOptions?: string[];
      printCount: number;
      paperlessCount: number;
      allPickupPointsCount: number;
      returnsFromDoorEnabled: boolean;
    };
  }>(),
);

export const setOrderDetails = createAction(
  SET_ORDER_DETAILS,
  props<{ orderDetails: OrderDetails }>(),
);

export const setPrinter = createAction(SET_PRINTER, props<{ printer: boolean }>());

export const setDropoffPoint = createAction(
  SET_DROPOFF_POINT,
  props<{ dropoffPoint: DropoffPoint }>(),
);

export const resetDropoffPoint = createAction(RESET_DROPOFF_POINT);

export const setExpiry = createAction(SET_EXPIRY, props<{ expiry: string }>());

export const setCode = createAction(SET_CODE, props<{ code: string }>());

export const setWaybillUrl = createAction(SET_WAYBILL_URL, props<{ waybillUrl: string }>());

export const setCreated = createAction(SET_CREATED, props<{ created: boolean }>());

export const resetOrder = createAction(RESET_ORDER);

export const setDropOffFlow = createAction(SET_DROP_OFF_FLOW, props<{ flow: boolean }>());

export const setWaybill = createAction(SET_WAYBILL, props<{ waybill: string | undefined }>());

export const validateWaybill = createAction(
  VALIDATE_WAYBILL,
  props<{ waybill: string | undefined }>(),
);

export const validatedWaybill = createAction(
  VALIDATED_WAYBILL,
  props<{
    orderDetails: {
      first_name: string;
      last_name: string;
      phone_numbers: string[];
      email: string;
    };
    validated: boolean;
  }>(),
);

export const validateWaybillFailed = createAction(VALIDATE_WAYBILL_FAILED);

export const resetValidateWaybill = createAction(RESET_VALIDATE_WAYBILL);

export const getSupplierFailed = createAction(GET_SUPPLIER_FAILED);

export const validateOtp = createAction(VALIDATE_OTP, props<{ otp: string | undefined }>());

export const validatedOtp = createAction(
  VALIDATED_OTP,
  props<{ otp: string; validated: boolean }>(),
);

export const validateOtpFailed = createAction(VALIDATE_OTP_FAILED);

export const setNeedsGreenLight = createAction(
  SET_NEEDS_GREEN_LIGHT,
  props<{ greenLight: boolean }>(),
);

export const createReturn = createAction(CREATE_RETURN);

export const resetReturn = createAction(RESET_RETURN);

export const setWaybillDetails = createAction(
  SET_WAYBILL_DETAILS,
  props<{ waybillDetails: WaybillDetails }>(),
);
export const setWaybillType = createAction(SET_WAYBILL_TYPE, props<{ waybillType: string }>());

export const setPickup = createAction(SET_PICKUP, props<{ pickup: Pickup }>());

export const actions = {
  initApplication,
  navigateToStep,
  updateSkippedSteps,

  getSuppliers,
  addSuppliers,
  getSupplier,

  setCurrentSupplier,
  setOrderDetails,
  setPrinter,
  setDropoffPoint,
  resetDropoffPoint,
  setExpiry,
  setCode,
  setCreated,
  setWaybillUrl,
  setWaybill,

  validateOtp,
  validatedOtp,
  validateOtpFailed,

  validateWaybill,
  validatedWaybill,
  validateWaybillFailed,
  resetValidateWaybill,

  createReturn,
  setNeedsGreenLight,
  resetReturn,

  getSupplierFailed,
  setWaybillDetails,

  setDropOffFlow,
  setLandedOnBrand,
  resetCurrentSupplier,
  resetOrder,

  setSearchAddress,
  setPickup,
  setWaybillType,
};
