<header>
  <img alt="back" src="../../../../../../../assets/images/icons/ChevronLeft.svg" routerLink="/pup-selection" />
  <span class="title">{{ supplier.name }} Returns</span>
</header>

<div class="container">
  <div class="return-details">
    <h3>Return From</h3>
    <div class="error" [hidden]="!pickupDetailsValidation.address1">* Please select a different address a with number
      and a street name</div>
    <form [formGroup]="pickupDetailsForm" autocomplete="off">
      <div class="form-group">
        <input type="text" formControlName="address1" required placeholder=" "
          [class.invalid]="pickupDetailsValidation.address1" />
        <label>Number and Street</label>
      </div>
      <div class="form-group">
        <input type="text" formControlName="address2" placeholder=" "
          [class.invalid]="pickupDetailsValidation.address2" />
        <label>Complex / Building (Optional)</label>
      </div>
      <div class="form-group">
        <input type="text" formControlName="suburb" required placeholder=" "
          [class.invalid]="pickupDetailsValidation.suburb" />
        <label>Suburb</label>
      </div>
      <div class="form-group">
        <input type="text" formControlName="city" required placeholder=" "
          [class.invalid]="pickupDetailsValidation.city" />
        <label>Town</label>
      </div>
      <div class="form-group">
        <input type="text" formControlName="postalcode" required placeholder=" "
          [class.invalid]="pickupDetailsValidation.postalcode" />
        <label>Postal Code</label>
      </div>
      <div class="form-group">
        <input type="text" formControlName="province" required placeholder=" "
          [class.invalid]="pickupDetailsValidation.province" />
        <label>Province</label>
      </div>
      <div class="form-group">
        <input type="text" formControlName="country" required placeholder=" "
          [class.invalid]="pickupDetailsValidation.country" type="hidden" />
      </div>
    </form>
    <a [routerLink]="['/brand-landing']">Select a different address</a>
  </div>
  <div class="your-details">
    <h3>Additional Details</h3>

    <form [formGroup]="additionalDetailsForm" autocomplete="off">
      <div class="form-group">
        <textarea formControlName="additionalDetails" [class.invalid]="additionalDetailsValidation.additionalDetails"
          (input)="updateCharacterCount()" [maxlength]="maxCharacters"></textarea>
        <label>Return reason description</label>
        <div class="character-limit">{{ characterCount }}/{{ maxCharacters }}</div>
      </div>
    </form>
  </div>
</div>
<footer>
  <button class="confirm-btn" (click)="handleConfirm.emit()">Continue</button>
</footer>
