<ng-container *ngIf="selectedSupplier$ | async as supplier; else loading">
  <use-media query="(max-width: 1023px)">
    <app-brand-landing-mobile
      (selectAddress)="selectAddress($event)"
      (navigate)="navigate($event)"
      (handleAutofillQuery)="handleAutofillQuery($event)"
      [supplier]="supplier"
      [automaticSelection]="landedOnBrand$ | async"
      [autofillSuggestions$]="autofillSuggestions$"
    ></app-brand-landing-mobile>
  </use-media>
  <use-media query="(min-width: 1024px)">
    <app-brand-landing-desktop
      (selectAddress)="selectAddress($event)"
      (navigate)="navigate($event)"
      (handleAutofillQuery)="handleAutofillQuery($event)"
      [supplier]="supplier"
      [automaticSelection]="landedOnBrand$ | async"
      [autofillSuggestions$]="autofillSuggestions$"
    ></app-brand-landing-desktop>
  </use-media>
</ng-container>
<ng-template #loading>
  <div class="loader">
    <span>
      <span class="spinner spinner-slow" role="status"></span>
      &nbsp;Loading...
    </span>
  </div>
</ng-template>
