<use-media query="(max-width: 1023px)">
  <app-brand-selection-mobile
    (changeSelectedSupplier)="changeSelectedSupplier($event)"
    (bookReturn)="bookReturn()"
    [searchTerm]="searchTerm"
    [suppliers$]="suppliers$"
    [searchSuppliers$]="searchSuppliers$"
    [selectedSupplier$]="selectedSupplier$"
    [failed]="fetchSupplierFailed$"
    [selectedSupplierId]="selectedSupplierId"
  ></app-brand-selection-mobile>
</use-media>
<use-media query="(min-width: 1024px)">
  <app-brand-selection-desktop
    (changeSelectedSupplier)="changeSelectedSupplier($event)"
    (bookReturn)="bookReturn()"
    [searchTerm]="searchTerm"
    [suppliers$]="suppliers$"
    [searchSuppliers$]="searchSuppliers$"
    [selectedSupplier$]="selectedSupplier$"
    [failed]="fetchSupplierFailed$"
    [selectedSupplierId]="selectedSupplierId"
  ></app-brand-selection-desktop>
</use-media>
