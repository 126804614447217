import { Component, OnInit } from '@angular/core';
import { PupSelectionConfig, Supplier } from '../../../models/suppliers';
import { getSupplier } from '../../../actions/return';
import { Store } from '@ngrx/store';
import { selectCurrentSupplier } from '../../../selectors/return';
import { ActivatedRoute } from '@angular/router';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-amazon',
  templateUrl: './amazon.component.html',
  styleUrls: ['./amazon.component.scss'],
})
export class AmazonComponent implements OnInit {
  loading = true;

  pupSelectionConfig: PupSelectionConfig = {
    subtitle: 'Explore the map and find a Pargo Point that suits you.',
    displayBackButton: false,
    displayInstructions: true,
    processTypes: null,
    defaultProcessType: 'paperless',
    displayLogo: false,
  };

  supplier: Supplier = {
    id: env.amazonSupId,
    name: 'Amazon',
  };

  selectedSupplier$ = this.store.select(selectCurrentSupplier);

  constructor(private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.store.dispatch(getSupplier(this.supplier));
    this.selectedSupplier$.subscribe((supplier) => {
      if (supplier?.config) {
        this.loading = false;
      }
    });
  }
}
