import { Component } from '@angular/core';
import {
  selectCurrentSupplier,
  selectDropoff,
  selectPickup,
  selectWaybillDetails,
} from '../../selectors/return';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Supplier, WaybillDetails } from '../../models/suppliers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { createReturn } from '../../actions/return';

@Component({
  selector: 'app-waybill-confirmation',
  templateUrl: './waybill-confirmation.component.html',
  styleUrls: ['./waybill-confirmation.component.scss'],
})
export class WaybillConfirmationComponent {
  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier);

  readonly selectedPickupPoint$ = this.store.select(selectDropoff);

  readonly selectedPickupAddress$ = this.store.select(selectPickup);

  readonly waybillDetails$ = this.store.select(selectWaybillDetails);

  readonly pupAddress$ = this.selectedPickupPoint$.pipe(
    map(
      (p) =>
        p &&
        p.point &&
        `${p.point.address1}, ${p.point.suburb}, ${p.point.city}, ${p.point.postalcode}`,
    ),
  );

  readonly config$: Maybe<
    Observable<{
      supplier: Maybe<Supplier>;
      waybill: Maybe<WaybillDetails> | any;
    }>
  > = null;

  constructor(private store: Store, public router: Router) {
    this.config$ = combineLatest(this.selectedSupplier$, this.waybillDetails$).pipe(
      take(1),
      map(([supplier, waybill]) => ({
        supplier,
        waybill,
      })),
    );

    this.config$.subscribe((data) => {
      if (!data.supplier) {
        this.router.navigate(['/brand-selection']);
      }
    });
  }

  handleConfirm(): void {
    this.store.dispatch(createReturn());
    this.router.navigate(['/confirm']);
  }

  goBack(): void {
    this.router.navigate([`/waybill`]);
  }
}
