<h1>
  Pargo Returns
  <img src="/assets/images/logo.svg" alt="Pargo" class="logo" />
</h1>

<div class="steps__container">
  <div class="step">
    <h3>
      <span class="icon">1</span>
      Select Brand
    </h3>
    <img src="/assets/images/returns/select-brand.svg" alt="" />
    <p>Select the brand for your return.</p>
  </div>
  <div class="step">
    <h3>
      <span class="icon">2</span>
      Your Details
    </h3>
    <img src="/assets/images/returns/fill-in-details.svg" alt="" />
    <p>Submit your contact and return details.</p>
  </div>
  <div class="step">
    <h3>
      <span class="icon">3</span>
      Drop it off
    </h3>
    <img src="/assets/images/returns/drop-it-off.svg" alt="" />
    <p>Drop off your parcel at the Pargo point.</p>
  </div>
</div>
<h1>Choose your Brand</h1>

<div class="supplier-container">
  <div class="search-container">
    <ng-container *ngIf="searchSuppliers$ | async as data">
      <div class="form-group">
        <input type="search" [formControl]="searchTerm" placeholder=" " />
        <label>Brand name</label>
        <i class="search-icon"></i>
      </div>
      <ng-container *ngIf="data.suppliers as suppliers">
        <ul class="supplier-list">
          <li
            *ngFor="let supplier of suppliers"
            [class.selected]="selectedSupplierId === supplier.id"
            (click)="changeSelectedSupplier.emit(supplier)"
          >
            <span class="text">{{ supplier.name }}</span>
            <span class="icon"><span>&nbsp;</span></span>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="data.isLoading">
        <br />
        <span>
          <span class="spinner spinner-slow" role="status"></span>
          &nbsp;Loading...
        </span>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="selectedSupplierId">
    <ng-container *ngIf="selectedSupplier$ | async as data">
      <div class="selected-supplier-container">
        <div class="selected-supplier">
          <ng-container *ngIf="data.value as supplier">
            <h1>
              <img
                [src]="supplier.config?.logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              Returns for {{ supplier.name }}
            </h1>
            <p class="description">
              {{ supplier.config?.returnDescription }}
            </p>
            <button (click)="bookReturn.emit(supplier)">Log a Return</button>
          </ng-container>
          <ng-container *ngIf="data.isLoading || !data.value">
            <br />
            <span>
              <span class="spinner spinner-slow" role="status"></span>
              &nbsp;Loading...
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
