import { Action, ActionReducerMap, combineReducers } from '@ngrx/store';

import { InitialState as ReturnState, returnReducer, returnReducerKey } from './return';

export interface InitialAppState {
  return: ReturnState;
}

export const reducerKeys: string[] = [returnReducerKey];

export const reducers: ActionReducerMap<{ return: any }, Action> = {
  return: returnReducer,
};

export default combineReducers(reducers, {});
