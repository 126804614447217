import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import {
  selectCurrentSupplier,
  selectGetSupplierFailed,
  selectSearchAddress,
} from '../../selectors/return';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { getSupplier, setDropOffFlow, setDropoffPoint } from '../../actions/return';
import { PupSelectionConfig, Supplier } from '../../models/suppliers';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pup-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pup-selection.component.html',
  styleUrls: ['./pup-selection.component.scss'],
})
export class PupSelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  private params$: Subscription;

  private fetchSupplierFailedSub$: Subscription;

  public automaticSelection = false;

  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier).pipe(
    distinctUntilChanged(() => {
      return false;
    }),
  );

  @Input() supplierPupSelectionConfig: PupSelectionConfig = {
    subtitle: 'Select the Pargo Point from the list below.',
    displayBackButton: true,
    displayInstructions: false,
    processTypes: {
      paperless: true,
      print: true,
      allPickupPoints: true,
    },
    defaultProcessType: 'all',
    displayLogo: true,
  };

  readonly searchAddress$ = this.store.select(selectSearchAddress);

  readonly fetchSupplierFailed$ = this.store
    .select(selectGetSupplierFailed)
    .pipe(distinctUntilChanged());

  searchTerm = new UntypedFormControl('');

  processType = 'all';

  constructor(
    private store: Store,
    public router: Router,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
  ) {
    this.params$ = combineLatest(this.route.params, this.selectedSupplier$)
      .pipe(take(1))
      .subscribe((args) => this.handlePreloadBrand(...args));
    this.fetchSupplierFailedSub$ = this.fetchSupplierFailed$.subscribe((failed) => {
      if (failed) {
        this.router.navigate(['/error']);
      }
    });

    this.sanitizer = sanitizer;
  }

  ngOnInit(): void {
    if (this.supplierPupSelectionConfig.defaultProcessType !== 'all') {
      this.changeProcessType(this.supplierPupSelectionConfig.defaultProcessType);
    }
  }

  ngAfterViewInit(): void {
    window.addEventListener('message', this.handleMapEvent.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleMapEvent.bind(this));

    this.params$.unsubscribe();
    this.fetchSupplierFailedSub$.unsubscribe();
  }

  handlePreloadBrand(params: Params, supplier?: Supplier): void {
    if (!params.segment && !supplier?.config) {
      this.router.navigate(['/']);
    }

    if (params.segment && !supplier?.config) {
      this.automaticSelection = true;
      this.store.dispatch(getSupplier({ id: params.segment }));
    }
  }

  changeProcessType(type: string): void {
    this.processType = type;
    this.store.dispatch(setDropOffFlow({ flow: type === 'paperless' }));
  }

  handleMapEvent(event: MessageEvent): void {
    // not meant to be secure, only a filter
    if (/.+map.+pargo.+/.test(event.origin) && event.data.hasOwnProperty('address1')) {
      this.store.dispatch(setDropoffPoint({ dropoffPoint: event.data }));
      this.router.navigate(['/waybill']);

      event.preventDefault();
      event.stopPropagation();
    }
  }
}
