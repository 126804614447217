import { Component } from '@angular/core';
import {
  selectCurrentSupplier,
  selectDropoff,
  selectPickup,
  selectWaybillDetails,
  selectWaybillType,
  selectWaybillValidation,
} from '../../selectors/return';
import { Store } from '@ngrx/store';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier);

  readonly selectedPickupPoint$ = this.store.select(selectDropoff);

  readonly selectedPickupAddress$ = this.store.select(selectPickup);

  readonly waybillType$ = this.store.select(selectWaybillType);

  readonly waybillDetails$ = this.store.select(selectWaybillDetails);

  readonly waybillValidation$ = this.store.select(selectWaybillValidation);

  readonly dropoff$ = this.store.select(selectDropoff).pipe(
    map((dropoff) => ({
      isLoading: !dropoff?.created,
      value: dropoff?.created ? dropoff : false,
    })),
    startWith({ isLoading: true, value: false }),
  );

  constructor(private store: Store) {}

  download(url: string | undefined) {
    window.open(url);
  }
}
