<ng-container *ngIf="selectedSupplier$ | async as supplier">
  <ng-container *ngIf="selectedPickupPoint$ | async as pickupPoint">
    <ng-container *ngIf="selectedPickupAddress$ | async as pickupAddress">
      <ng-container *ngIf="waybillDetails$ | async as waybillDetails">
        <use-media query="(max-width: 1023px)">
          <app-waybill-confirmation-mobile
            (handleConfirm)="handleConfirm()"
            (goBack)="goBack()"
            [supplier]="supplier"
            [waybillDetails]="waybillDetails"
            [pickupPoint]="pickupPoint"
            [pickupAddress]="pickupAddress"
          >
          </app-waybill-confirmation-mobile>
        </use-media>
        <use-media query="(min-width: 1024px)">
          <app-waybill-confirmation-desktop
            (handleConfirm)="handleConfirm()"
            (goBack)="goBack()"
            [supplier]="supplier"
            [waybillDetails]="waybillDetails"
            [pickupPoint]="pickupPoint"
            [pickupAddress]="pickupAddress"
          >
          </app-waybill-confirmation-desktop>
        </use-media>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
