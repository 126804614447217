import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-brand-landing-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class BrandLandingMobileComponent {
  searchTerm = new FormControl();

  @Output() selectAddress = new EventEmitter();

  @Output() navigate = new EventEmitter();

  @Output() handleAutofillQuery = new EventEmitter();

  @Input() supplier: any;

  @Input() automaticSelection: any;

  @Input() autofillSuggestions$: any;

  constructor() {
    this.searchTerm.valueChanges.subscribe((value) => this.handleAutofillQuery.emit(value));
  }
}
