import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorStateComponent } from './containers/error-state/error-state.component';
import { BrandSelectionComponent } from './containers/brand-selection/brand-selection.component';
import { PupSelectionComponent } from './containers/pup-selection/pup-selection.component';
import { WaybillInfoComponent } from './containers/waybill-info/waybill-info.component';
import { BrandLandingComponent } from './containers/brand-landing/brand-landing.component';
import { WaybillConfirmationComponent } from './containers/waybill-confirmation/waybill-confirmation.component';
import { ConfirmationComponent } from './containers/confirmation/confirmation.component';
import { AddressInfoComponent } from './containers/address-info/address-info.component';
import { Store } from './store';
import { CanActivateConfirmed } from './guards/confirmed.guard';
import { AmazonComponent } from './containers/brand-specific-journeys/amazon/amazon.component';

const routes: Routes = [
  {
    path: 'brand/:segment',
    pathMatch: 'full',
    component: BrandLandingComponent,
  },
  {
    path: 'pup-selection',
    component: PupSelectionComponent,
    canActivate: [CanActivateConfirmed],
  },
  {
    path: 'brand-selection',
    component: BrandSelectionComponent,
  },
  {
    path: 'brand-landing',
    component: BrandLandingComponent,
  },
  {
    path: 'waybill',
    component: WaybillInfoComponent,
    canActivate: [CanActivateConfirmed],
  },
  {
    path: 'address',
    component: AddressInfoComponent,
    canActivate: [],
  },
  {
    path: 'waybill-confirm',
    component: WaybillConfirmationComponent,
    canActivate: [CanActivateConfirmed],
  },
  {
    path: 'confirm',
    component: ConfirmationComponent,
  },
  {
    path: 'amazon',
    component: AmazonComponent,
  },
  { path: 'not-found', component: ErrorStateComponent },
  {
    path: 'error',
    component: ErrorStateComponent,
  },
  {
    path: ':segment',
    pathMatch: 'full',
    component: BrandLandingComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'brand-selection',
  },
  {
    path: '**',
    redirectTo: 'brand-selection',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), Store],
  exports: [RouterModule],
  providers: [CanActivateConfirmed],
})
export class ReturnsRoutingModule {}
