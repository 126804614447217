import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-waybill-confirmation-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss'],
})
export class WaybillConfirmationDesktopComponent {
  @Output() handleConfirm = new EventEmitter();

  @Output() goBack = new EventEmitter();

  @Input() supplier: any;

  @Input() waybillDetails: any;

  @Input() pickupPoint: any;

  @Input() pickupAddress: any;
}
