import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { selectCurrentSupplier, selectDropoff, selectWaybillDetails } from '../../selectors/return';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DropoffPoint, Supplier, WaybillDetails } from '../../models/suppliers';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { setWaybillDetails } from '../../actions/return';

@Component({
  selector: 'app-waybill-info',
  templateUrl: './waybill-info.component.html',
  styleUrls: ['./waybill-info.component.scss'],
})
export class WaybillInfoComponent implements OnInit {
  readonly selectedSupplier$ = this.store.select(selectCurrentSupplier);

  readonly selectedPickupPoint$ = this.store.select(selectDropoff);

  readonly waybillDetails$ = this.store.select(selectWaybillDetails);

  readonly pupAddress$ = this.selectedPickupPoint$.pipe(map((p) => p && p.point && ``));

  readonly config$: Maybe<
    Observable<{
      supplier: Maybe<Supplier>;
      pickupPoint: DropoffPoint | any;
    }>
  > = null;

  returnDetailsForm = new UntypedFormGroup({
    orderNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(/\w/)]),
    reasonForReturn: new UntypedFormControl('', [Validators.required]),
    additionalDetails: new UntypedFormControl('', [Validators.maxLength(250)]),
  });

  yourDetailsForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.pattern(/\w/)]),
    surname: new UntypedFormControl('', [Validators.required, Validators.pattern(/\w/)]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobileNumber: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[\d\+]+$/),
    ]),
  });

  get returnDetailsValidation(): Record<string, boolean | undefined> {
    const orderNumber = this.returnDetailsForm.get('orderNumber');
    const reasonForReturn = this.returnDetailsForm.get('reasonForReturn');
    const additionalDetails = this.returnDetailsForm.get('additionalDetails');

    return {
      orderNumber: orderNumber?.touched && orderNumber.invalid,
      reasonForReturn: reasonForReturn?.touched,
      additionalDetails: additionalDetails?.touched && additionalDetails.invalid,
    };
  }

  get yourDetailsValidation(): Record<string, boolean | undefined> {
    const firstName = this.yourDetailsForm.get('firstName');
    const surname = this.yourDetailsForm.get('surname');
    const emailAddress = this.yourDetailsForm.get('emailAddress');
    const mobileNumber = this.yourDetailsForm.get('mobileNumber');

    return {
      firstName: firstName?.touched && firstName.invalid,
      surname: surname?.touched && surname.invalid,
      emailAddress: emailAddress?.touched && emailAddress.invalid,
      mobileNumber: mobileNumber?.touched && mobileNumber.invalid,
    };
  }

  constructor(private store: Store, public router: Router) {}

  ngOnInit(): void {
    this.waybillDetails$.pipe(take(1)).subscribe((waybillDetails) => {
      if (waybillDetails) {
        this.returnDetailsForm.patchValue(waybillDetails.returnDetails);
        this.yourDetailsForm.patchValue(waybillDetails.yourDetails);
      }
    });
  }

  getFormData(): WaybillDetails {
    return {
      yourDetails: {
        firstName: this.yourDetailsForm.get('firstName')?.value,
        surname: this.yourDetailsForm.get('surname')?.value,
        emailAddress: this.yourDetailsForm.get('emailAddress')?.value,
        mobileNumber: this.yourDetailsForm.get('mobileNumber')?.value,
      },
      returnDetails: {
        orderNumber: this.returnDetailsForm.get('orderNumber')?.value,
        reasonForReturn: this.returnDetailsForm.get('reasonForReturn')?.value,
        additionalDetails: this.returnDetailsForm.get('additionalDetails')?.value,
      },
    };
  }

  handleConfirm(): void {
    this.returnDetailsForm.markAllAsTouched();
    this.yourDetailsForm.markAllAsTouched();

    if (this.returnDetailsForm.valid && this.yourDetailsForm.valid) {
      const waybillDetails = this.getFormData();

      this.store.dispatch(setWaybillDetails({ waybillDetails }));
      this.router.navigate(['/waybill-confirm']);
    }
  }

  goBack(flow: boolean): void {
    const type = flow ? 'paperless' : 'print';
    const waybillDetails = this.getFormData();

    this.store.dispatch(setWaybillDetails({ waybillDetails }));
    this.router.navigate([`/pup-selection`], { queryParams: { type } });
  }
}
