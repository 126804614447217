<header>
  <img
    alt="back"
    src="../../../../../../../assets/images/icons/ChevronLeft.svg"
    routerLink="/waybill"
  />
  <span class="title">{{ supplier.name }} Returns</span>
</header>

<div class="container">
  <ng-container *ngIf="dropoff as data">
    <ng-container *ngIf="data.isLoading">
      <span>
        <span class="spinner spinner-slow" role="status"></span>
        &nbsp;Logging return...
      </span>
    </ng-container>
    <ng-container *ngIf="data.value as dropoff">
      <div class="description">
        <span class="info" *ngIf="supplier.config?.needsGreenLight"
          >Hi {{ waybillDetails?.yourDetails?.firstName }}, your return has been logged with
          {{ supplier.name }} .</span
        >
        <span class="info" *ngIf="!supplier.config?.needsGreenLight"
          >Hi {{ waybillDetails?.yourDetails?.firstName }}, your return is confirmed</span
        >
      </div>

      <ng-container *ngIf="supplier.config?.needsGreenLight && waybillType === 'D2W'">
        <!-- D2W Pending -->
        <div class="block-container">
          <div class="block">
            <div>
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>ALMOST READY!</h2>
              <p>
                Keep an eye on your inbox for confirmation from {{ supplier.name }} with next steps
                in getting your return parcel on the go.
              </p>
            </div>
          </div>
          <button class="black" (click)="toggleShow()">What next</button>

          <a *ngIf="supplier.config?.shopLink" (click)="download.emit(supplier.config?.shopLink)"
            >Shop at {{ supplier.name }}</a
          >
        </div>

        <div class="popover" [class.active]="showOverlay">
          <div class="overlay" (click)="toggleShow()"></div>
          <div class="overlay-content">
            <h2>Next Steps</h2>
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>
                  Securely package your return parcel and remove all old labels and waybills from
                  the parcel.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                </h3>
                <img src="/assets/images/returns/mobile.svg" alt="" />
                <p>
                  Wait for confirmation via SMS and email. Keep an eye on your inbox for next
                  instructions.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Be ready to hand over your return parcel to our couriers when they arrive.</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!supplier.config?.needsGreenLight && waybillType === 'D2W'">
        <!-- D2W Confirmed -->
        <div class="block-container">
          <div class="block">
            <div>
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>GET READY!</h2>
              <p>
                Our couriers have been notified to collect your {{ supplier.name }} return parcel.
                Keep an eye on your inbox for updates.
              </p>
            </div>
          </div>
          <div class="drop-off-code">
            {{ dropoff.code }}
          </div>
          <button class="black" (click)="toggleShow()">What next</button>

          <a *ngIf="supplier.config?.shopLink" (click)="download.emit(supplier.config?.shopLink)"
            >Shop at {{ supplier.name }}</a
          >
        </div>

        <div class="popover" [class.active]="showOverlay">
          <div class="overlay" (click)="toggleShow()"></div>
          <div class="overlay-content">
            <h2>Next Steps</h2>
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>
                  Securely package your return parcel and remove all old labels and waybills from
                  the parcel.
                </p>
              </div>

              <div class="step">
                <h3>
                  <span class="icon">2</span>
                </h3>
                <img src="/assets/images/returns/mobile.svg" alt="" />
                <p>
                  Keep an eye on your inbox for further instruction from Pargo via SMS and email.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Be ready to hand over your return parcel to our couriers when they arrive.</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="supplier.config?.needsGreenLight && dropoff.flow && waybillType !== 'D2W'"
      >
        <!-- Paperless Pending -->
        <div class="block-container">
          <div class="block">
            <div>
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Confirmation Pending</h2>
              <p>
                Look out for {{ supplier.name }} confirmation email with your drop-off code and next
                steps in getting your parcel on the go.
              </p>
            </div>
          </div>
          <button class="black" (click)="toggleShow()">What next</button>

          <a *ngIf="supplier.config?.shopLink" (click)="download.emit(supplier.config?.shopLink)"
            >Shop at {{ supplier.name }}</a
          >
        </div>

        <div class="popover" [class.active]="showOverlay">
          <div class="overlay" (click)="toggleShow()"></div>
          <div class="overlay-content">
            <h2>Next Steps</h2>
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                </h3>
                <img src="/assets/images/returns/clock.svg" alt="" />
                <p>{{ supplier.name }} will notify you once they've confirmed your return.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>Prepare your parcel making sure to remove all old labels and stickers.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>
                  You'll need your unique drop off code when you visit your selected Pargo Point.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="supplier.config?.needsGreenLight && !dropoff.flow && waybillType !== 'D2W'"
      >
        <!-- Print Pending -->
        <div class="block-container">
          <div class="block">
            <div>
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Confirmation Pending</h2>
              <p>
                Look out for {{ supplier.name }} confirmation email with next steps in getting your
                parcel on the go.
              </p>
            </div>
          </div>
          <button class="black" (click)="toggleShow()">What next</button>

          <a *ngIf="supplier.config?.shopLink" (click)="download.emit(supplier.config?.shopLink)"
            >Shop at {{ supplier.name }}</a
          >
        </div>

        <div class="popover" [class.active]="showOverlay">
          <div class="overlay" (click)="toggleShow()"></div>
          <div class="overlay-content">
            <h2>Next Steps</h2>
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                </h3>
                <img src="/assets/images/returns/clock.svg" alt="" />
                <p>{{ supplier.name }} will notify you once they've confirmed your return.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>
                  Print your label and prepare your parcel making sure to remove all old labels and
                  stickers.
                </p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Drop off your parcel at your selected Pargo Point.</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="!supplier.config?.needsGreenLight && dropoff.flow && waybillType !== 'D2W'"
      >
        <!-- Paperless Confirmed -->
        <div class="block-container">
          <div class="block">
            <div>
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Return Confirmed</h2>
            </div>

            <div class="drop-off-container">
              <span>Drop-off code</span>
              <div>
                {{ dropoff.code }}
              </div>

              <span>Pargo Point</span>
              <div>
                {{ pickupPoint.point?.storeName }}
              </div>

              <span>Address</span>
              <div>
                {{ pickupPoint.point?.address1 }},
                {{ pickupPoint.point?.address2 }}
              </div>

              <span>Drop-off Expiry</span>
              <div>
                {{ dropoff.expiry * 1000 | date: "medium" }}
              </div>
            </div>
          </div>
          <div class="drop-off-code">
            {{ dropoff.code }}
          </div>
          <button class="black" (click)="toggleShow()">What next</button>

          <a *ngIf="supplier.config?.shopLink" (click)="download.emit(supplier.config?.shopLink)"
            >Shop at {{ supplier.name }}</a
          >
        </div>

        <div class="popover" [class.active]="showOverlay">
          <div class="overlay" (click)="toggleShow()"></div>
          <div class="overlay-content">
            <h2>Next Steps</h2>
            <div class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                </h3>
                <img src="/assets/images/returns/clock.svg" alt="" />
                <p>You'll need this code to drop off your return at your selected Pargo Point.</p>
                <span class="drop-off-code">{{ dropoff.code }}</span>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>Prepare your parcel making sure to remove all old labels and stickers</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Drop off your parcel at your selected Pargo Point.</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="!supplier.config?.needsGreenLight && !dropoff.flow && waybillType !== 'D2W'"
      >
        <!-- Print Confirmed -->
        <div class="block-container">
          <div class="block">
            <div>
              <img src="/assets/images/returns/success.svg" alt="Success" />
              <br />
              <img
                [src]="supplier.config?.logo"
                class="logo"
                *ngIf="supplier.config?.logo"
                [alt]="supplier.name"
              />
              <br />
              <h2>Return Confirmed</h2>
            </div>

            <div class="drop-off-container">
              <span>Pargo Point</span>
              <div>
                {{ pickupPoint.point?.storeName }}
              </div>

              <span>Address</span>
              <div>
                {{ pickupPoint.point?.address1 }},
                {{ pickupPoint.point?.address2 }}
              </div>

              <span>Drop-off Expiry</span>
              <div>
                {{ dropoff.expiry * 1000 | date: "medium" }}
              </div>
            </div>
          </div>
          <button (click)="download.emit(waybillValidation.waybillUrl)">Download to Print</button>
          <button class="black" (click)="toggleShow()">What next</button>
          <a *ngIf="supplier.config?.shopLink" (click)="download.emit(supplier.config?.shopLink)"
            >Shop at {{ supplier.name }}</a
          >
        </div>

        <div class="popover" [class.active]="showOverlay">
          <div class="overlay" (click)="toggleShow()"></div>
          <div class="overlay-content">
            <h2>Next Steps</h2>
            <div *ngIf="waybillType !== 'D2W'" class="steps__container">
              <div class="step">
                <h3>
                  <span class="icon">1</span>
                </h3>
                <img src="/assets/images/returns/print-screen.svg" alt="" />
                <p>Print and secure the label to your parcel where it can clearly be seen.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">2</span>
                </h3>
                <img src="/assets/images/returns/box.svg" alt="" />
                <p>Prepare your parcel making sure to remove all old labels and stickers.</p>
              </div>
              <div class="step">
                <h3>
                  <span class="icon">3</span>
                </h3>
                <img src="/assets/images/returns/drop-it-off.svg" alt="" />
                <p>Drop off your parcel at your selected Pargo Point.</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
